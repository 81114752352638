import Vue from 'vue';
import Vuex from 'vuex';
import { authModule } from './authModule';
import { ordersModule } from './ordersModule';
import { constructionWorkModule } from './constructionWorkModule';
import { productAvailabilityModule } from './productAvailabilityModule';
import { orderDetailsModule } from './orderDetailsModule';
import { additionalContacModule } from './additionalContactModule';
import { applicantModule } from './applicantModule';
import { landownerModule } from './landownerModule';
import { invoiceAddressModule } from './invoiceAddressModule';
import { installersModule } from './installersModule';
import { installationAddressModule } from './installationAddressModule';
import { pricingModule } from './pricingModule';
import { historyModule } from './historyModule';
import { installationsModule } from './installationsModule';

import { orderUploadFilesModule } from './orderUploadFilesModule';
import { technicalInspectionModule } from './technicalInspectionModule';
import { installerCompanyDataModule } from './installerCompanyDataModule';
import { installerInspectionModule } from './installerInspectionModule';
import { installerUploadFilesModule } from './installerUploadFilesModule';
import { emobilityModule } from './emobilityModule';
import { emobilityApplicantModule } from './emobilityApplicantModule';
import { emobilityInstallationAddressModule } from './emobilityInstallationAddressModule';
import { emobilityAdditionalContactModule } from './emobilityAdditionalContactModule';
import { emobilityLandownerModule } from './emobilityLandownerModule';
import { emobilityWallboxLocationModule } from './emobilityWallboxLocationModule';
import { emobilityChargingPowerModule } from './emobilityChargingPowerModule';
import { emobilityUploadFilesModule } from './emobilityUploadFilesModule';
import { emobilityInspectionModule } from './emobilityInspectionModule';
import { installerConcessionDivisonModule } from './installerConcessionDivisionModule';
import { mfhInstallationsModule } from './mfhInstallationsModule';
import { feedInSystemsModule } from './feedInSystemsModule';
import { photovoltaicModule } from './photovoltaicModule';
import { pvUploadFilesModule } from './pvUploadFilesModule';
import { feedInSystemsMarketDataRegisterModule } from './feedInSystemsMarketDataRegisterModule';
import { feedInSystemsStorageMarketDataRegisterModule } from './feedInSystemsStorageMarketDataRegisterModule';
import { feedInSystemsCounterModule } from './feedInSystemsCounterModule';
import { feedInSystemsUpdateCounterModule } from './feedInSystemsUpdateCounterModule';
import { feedInSystemsContractContactModule } from './feedInSystemsContractContactModule';
import { photovoltaicInstallationAddressModule } from './photovoltaicInstallationAddressModule';
import { notificationsModule } from './notificationsModule';
import { feedInSystemsTechnicalInspectionModule } from './feedInSystemsTechnicalInspectionModule';
import { InverterModelModule } from './InverterModelModule';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    changed_object: {},
    old_value_object: {},
    current_component_status: 'finished',
    previous_page: null,
    note: '',
    stepValue: { step_count: 1 },
    show_spinner_in_modal: false,
    show_modal_update_error: false,
    mustBeNotifiedOfChangedData: false,
    showConfirmationDialog: false,
    pendingNavigation: null,
  },
  mutations: {
    setPendingNavigation(state, newVal) {
      state.pendingNavigation = newVal;
    },
    setMustBeNotifiedOfChangedData(state, newVal) {
      state.mustBeNotifiedOfChangedData = newVal;
    },
    setShowConfirmationDialog(state, newVal) {
      state.showConfirmationDialog = newVal;
    },
    setChangedObject(state, changedObject) {
      state.changed_object = changedObject;
    },
    setCurrentComponentStatus(state, current_component_status) {
      state.current_component_status = current_component_status;
    },
    setOldValueObject(state, changedObject) {
      state.old_value_object = changedObject;
    },
    deleteChangedObject(state) {
      state.changed_object = {};
    },
    deleteOldValueObject(state) {
      state.old_value_object = {};
    },
    updateNoteChangedObject(state, note) {
      state.note = note;
    },
    setPreviousPage(state, page) {
      state.previous_page = page;
    },
    setSupportStepValue(state, step_count) {
      state.stepValue.step_count = step_count;
    },
    setShowSpinnerInModal(state, show_spinner_in_modal) {
      state.show_spinner_in_modal = show_spinner_in_modal;
    },
    setShowModalUpdateError(state, show_modal_update_error) {
      state.show_modal_update_error = show_modal_update_error;
    },
  },
  getters: {
    getPendingNavigation(state) {
      return state.pendingNavigation;
    },
    getMustBeNotifiedOfChangedData(state) {
      return state.mustBeNotifiedOfChangedData;
    },
    getShowConfirmationDialog(state) {
      return state.showConfirmationDialog;
    },
    getChangedObject(state) {
      return state.changed_object;
    },
    getOldValueObject(state) {
      return state.old_value_object;
    },
    getNoteChangedObject(state) {
      return state.note;
    },
    getCurrentComponentStatus(state) {
      return state.current_component_status;
    },
    getPreviousPage(state) {
      return state.previous_page;
    },
    getSupportStepValue(state) {
      return state.stepValue;
    },
    getShowSpinnerInModal(state) {
      return state.show_spinner_in_modal;
    },
    getShowModalUpdateError(state) {
      return state.show_modal_update_error;
    },
  },
  actions: {},
  modules: {
    auth: authModule,
    applicant: applicantModule,
    landowner: landownerModule,
    orders: ordersModule,
    construction_work: constructionWorkModule,
    product_availability: productAvailabilityModule,
    order_details: orderDetailsModule,
    additional_contact_person: additionalContacModule,
    invoice_address: invoiceAddressModule,
    installers: installersModule,
    installations: installationsModule,
    installationAddress: installationAddressModule,
    pricing: pricingModule,
    history: historyModule,
    orderUploadFiles: orderUploadFilesModule,
    technicalInspection: technicalInspectionModule,
    compay_data: installerCompanyDataModule,
    installer_inspection: installerInspectionModule,
    installerUploadFiles: installerUploadFilesModule,
    emobility: emobilityModule,
    emobilityApplicant: emobilityApplicantModule,
    emobilityInstallationAddress: emobilityInstallationAddressModule,
    emobilityAdditionalContact: emobilityAdditionalContactModule,
    emobilityLandowner: emobilityLandownerModule,
    emobilityWallboxLocation: emobilityWallboxLocationModule,
    emobilityChargingPower: emobilityChargingPowerModule,
    emobilityUploadFiles: emobilityUploadFilesModule,
    emobilityInspection: emobilityInspectionModule,
    installerConcessionDivison: installerConcessionDivisonModule,
    mfhInstallations: mfhInstallationsModule,
    feedInSystems: feedInSystemsModule,
    photovoltaic: photovoltaicModule,
    pvUploadFiles: pvUploadFilesModule,
    feedInSystemsMarketDataRegister: feedInSystemsMarketDataRegisterModule,
    feedInSystemsStorageMarketDataRegister:
      feedInSystemsStorageMarketDataRegisterModule,
    feedInSystemsCounter: feedInSystemsCounterModule,
    feedInSystemsUpdateCounterModule: {
      namespaced: true,
      ...feedInSystemsUpdateCounterModule,
    },
    feedInSystemsContractContact: feedInSystemsContractContactModule,
    photovoltaicInstallationAddress: photovoltaicInstallationAddressModule,
    notifications: notificationsModule,
    feedInSystemsTechnicalInspection: feedInSystemsTechnicalInspectionModule,
    inverterModel: InverterModelModule,
  },
});

export default store;
