export const feedInSystemsCounterModule = {
  state: { is_counter_available: null },
  getters: {
    getIsCounterAvailable: ({ is_counter_available }) => is_counter_available,
    getCounterNumberByPosition: (state, getters, rootState) => (position) => {
      if (!Array.isArray(rootState.changed_object.counter_id_data)) {
        return null;
      }
      return (
        rootState.changed_object.counter_id_data[position]?.counter_id || null
      );
    },
    getCounterFileNameByPosition: (state, getters, rootState) => (position) => {
      if (!Array.isArray(rootState.changed_object.counter_id_data)) {
        return null;
      }
      return (
        rootState.changed_object.counter_id_data[position]?.file_name || null
      );
    },
    getIsTwoWayMeasurementByCounterPosition:
      (state, getters, rootState) => (position) => {
        if (!Array.isArray(rootState.changed_object.counter_id_data)) {
          return null;
        }
        return (
          rootState.changed_object.counter_id_data[position]
            ?.two_way_measurement_built_in || null
        );
      },
    getCounterIdToRemove: (state, getters, rootState) => {
      return rootState.changed_object.counter_id_to_remove || null;
    },
    getSimultaneousPower(state, getters, rootState) {
      return (
        rootState.changed_object.new_counter_data?.simultaneous_power_w || null
      );
    },
    getConsumptionProspect(state, getters, rootState) {
      return (
        rootState.changed_object.new_counter_data?.consumption_prospect_wh ||
        null
      );
    },
    getCommissioningApplicationFileName(state, getters, rootState) {
      return (
        rootState.changed_object.new_counter_data
          ?.commissioning_application_file_name || null
      );
    },
  },

  mutations: {
    setIsCounterAvailable(state, value) {
      state.is_counter_available = value;
      if (value === false) {
        this.state.changed_object.counter_id_data = null;
        this.state.changed_object.new_counter_data = {
          simultaneous_power_w: null,
          consumption_prospect_wh: null,
          commissioning_application_file_name: null,
        };
      }
      if (value === true) {
        this.state.changed_object.new_counter_data = null;
      }
    },
    setCounterNumber(state, object) {
      if (!Array.isArray(this.state.changed_object.counter_id_data)) {
        this.state.changed_object.counter_id_data = [];
      }

      const counterData = this.state.changed_object.counter_id_data;

      if (!counterData[object.position]) {
        counterData[object.position] = {};
      }

      counterData[object.position].counter_id = object.value;
    },

    setCounterFileName(state, object) {
      if (!Array.isArray(this.state.changed_object.counter_id_data)) {
        this.state.changed_object.counter_id_data = [];
      }

      const counterData = this.state.changed_object.counter_id_data;

      if (!counterData[object.position]) {
        counterData[object.position] = {};
      }

      counterData[object.position].file_name = object.value;
    },

    setIsTwoWayMeasurementByCounterPosition(state, object) {
      if (!Array.isArray(this.state.changed_object.counter_id_data)) {
        this.state.changed_object.counter_id_data = [];
      }

      const counterData = this.state.changed_object.counter_id_data;

      if (!counterData[object.position]) {
        counterData[object.position] = {};
      }

      counterData[object.position].two_way_measurement_built_in = object.value;
    },
    setCounterIdToRemove(state, value) {
      this.state.changed_object.counter_id_to_remove = value;
    },
    setSimultaneousPower(state, value) {
      this.state.changed_object.new_counter_data.simultaneous_power_w = value;
    },

    setConsumptionProspect(state, value) {
      this.state.changed_object.new_counter_data.consumption_prospect_wh =
        value;
    },

    setCommissioningApplicationFileName(state, value) {
      this.state.changed_object.new_counter_data.commissioning_application_file_name =
        value;
    },
  },
};
