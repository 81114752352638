<template>
  <div>
    <div
      v-if="selectedInstaller && Object.keys(selectedInstaller).length !== 0"
    >
      <div class="page_title">Konzessionierungsantrag</div>
      <NewGlassCard class="card-without-title default-mb" title="">
        <div class="header_container">
          <v-btn class="back-button" @click="goOnePageBack()"
            ><v-icon color="#6E6E6E">mdi-arrow-left</v-icon></v-btn
          >
          <div class="item_container">
            <div class="company_data">
              Firmenname: {{ selectedInstaller.company_data.company_name }}
            </div>
            <div class="company_address">
              Adresse: {{ selectedInstallerAddress }}
            </div>
            <div class="company_address">
              Konzession gültig bis
              {{
                new Date(selectedInstaller.expiry) | formatDate('DD.MM.YYYY')
              }}
            </div>
          </div>
          <v-btn
            rounded
            class="ml-10 status-button"
            :color="translateInstallerStatus[1]"
          >
            {{ translateInstallerStatus[0] }}
          </v-btn>
          <div class="inspection-button">
            <v-btn
              v-show="
                selectedInstallerStatus === 'LICENSE_REQUEST_CREATED' ||
                selectedInstallerStatus === 'LICENSE_REQUEST_EXPIRED'
              "
              class="mr-4"
              @click="changeStatus('LICENSE_REQUEST_CHECKING')"
              color="primary"
            >
              Prüfung starten
            </v-btn>
            <v-btn
              v-show="selectedInstallerStatus === 'LICENSE_REQUEST_ACTIVE'"
              class="mr-4"
              @click="showExpireDialog()"
              color="secondary"
            >
              Inaktiv setzen
            </v-btn>
          </div>
        </div>
      </NewGlassCard>

      <NewGlassCard
        v-if="selectedInstallerStatus === 'LICENSE_REQUEST_CHECKING'"
        title="Konzessionsantrag prüfen"
        class="default-mb"
      >
        <installer-inspection-component />
      </NewGlassCard>

      <NewGlassCard
        id="installer-details"
        :title="$t('installer_details.title')"
        class="default-mb z-index-0"
      >
        <installer-details
          :file_names="selectedInstaller.file_names"
          @show-modal="showModal($event)"
        />
      </NewGlassCard>

      <NewGlassCard
        :title="$t('installer_details.installer_id_card')"
        class="default-mb"
        v-if="selectedInstaller.badge_id"
      >
        <installer-id-component />
      </NewGlassCard>

      <div :class="this.$vuetify.breakpoint.xl ? 'xl-design-details-page' : ''">
        <NewGlassCard
          :class="
            this.$vuetify.breakpoint.xl
              ? 'xl-design-details-page-element default-mb ml-2'
              : 'default-mb'
          "
          class="z-index-0"
          :title="$t('history.title')"
        >
          <history-table :historyType="'Installer'"></history-table>
        </NewGlassCard>
        <NewGlassCard
          :class="
            this.$vuetify.breakpoint.xl
              ? 'xl-design-details-page-element default-mb ml-2'
              : 'default-mb'
          "
          class="z-index-0"
          :title="$t('document_history.title')"
        >
          <document-history-component :historyType="'Installer'" />
        </NewGlassCard>
      </div>

      <NewModalDialog
        v-if="isModalVisible"
        :value="noteValue"
        @cancel-data="closeModal"
        @update-note-data="updateNoteChangedObject"
        role="dialog"
      >
        <template v-slot:body>
          <dynamic-dialog-body
            class="body-style"
            :type="currentComponent"
            :componentProperties="componentProperties"
          />
          <div
            v-if="showModalUpdateError"
            style="width: fit-content"
            class="error-dialog ml-8"
          >
            {{ $t('feed_in_system_details.saving_failed_please_reload') }}
          </div>
        </template>
        <template v-slot:footer>
          <div v-show="showSpinner" class="update-event-spinner">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            />
          </div>
          <div v-if="!showSpinner">
            <button
              type="button"
              class="btn-save"
              @click="closeModalAndSaveData"
            >
              {{ $t('modal_dialog.save') }}
            </button>
            <button type="button" class="btn-cancel" @click="closeModal">
              {{ $t('modal_dialog.cancel') }}
            </button>
          </div>
          <v-alert
            type="error"
            class="alert_error ml-4"
            v-if="showAlert"
            text
            transition="scale-transition"
          >
            {{ $t('modal_dialog.saving_failed') }}
          </v-alert>
        </template>
      </NewModalDialog>
      <NewModalDialog
        role="dialog"
        v-if="isExpireDialogVisible"
        :enableNote="false"
        :saveButtonText="$t('installer_details.set_inactive')"
        @cancel-data="closeExpireDialog(false)"
        @save-data="closeExpireDialog(true)"
      >
        <template v-slot:body>
          <v-alert type="error" color="#9b004b">
            {{ $t('installer_details.set_inactive_confirm') }}
          </v-alert>
        </template>
      </NewModalDialog>
    </div>
    <div v-if="isServerError">
      <h1>
        {{ $t('installer_details.loading_error') }}
      </h1>
      <p>
        {{ $t('installer_details.error_hint') }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  NewGlassCard,
  NewModalDialog,
} from '@newnetzportal/netzportal-webapp-storybook-components';
import InstallerDetails from '../components/installer-details/InstallerDetails';
import HistoryTable from '../components/history-table/HistoryTable';
import InstallerIdComponent from '../components/installer-details/InstallerIdComponent';
import gql from 'graphql-tag';
import { EventBus } from '../main';
import DynamicDialogBody from '../components/order-steps-components/DynamicDialogBody';
import userService from '../services/UserService';
import { getInstallerById } from '../services/InstallerService';
import InstallerInspectionComponent from '../components/installer-inspection-component/InstallerInspectionComponent';
import isEqual from 'lodash.isequal';
import DocumentHistoryComponent from '../components/order-details/DocumentHistoryComponent';

export default {
  name: 'InstallerDetailsPage',
  components: {
    NewGlassCard,
    InstallerDetails,
    HistoryTable,
    InstallerIdComponent,
    NewModalDialog,
    DynamicDialogBody,
    InstallerInspectionComponent,
    DocumentHistoryComponent,
  },
  async created() {
    if (this.installer_id) {
      await this.getInstallerFromBackend(this.installer_id);
    }
  },
  data() {
    return {
      isModalVisible: false,
      noteValue: { note: '' },
      changedObject: this.$store.getters.getChangedObject,
      showAlert: false,
      installer_id: this.$route.params.id ? this.$route.params.id : '',
      isExpireDialogVisible: false,
      isServerError: false,
    };
  },
  computed: {
    showSpinner: {
      get() {
        return this.$store.getters.getShowSpinnerInModal;
      },
      set(value) {
        this.$store.commit('setShowSpinnerInModal', value);
      },
    },
    showModalUpdateError: {
      get() {
        return this.$store.getters.getShowModalUpdateError;
      },
      set(value) {
        this.$store.commit('setShowModalUpdateError', value);
      },
    },
    selectedInstaller() {
      return this.$store.getters.getSelectedInstaller;
    },
    selectedInstallerStatus() {
      return this.$store.getters.getSelectedInstaller.status;
    },
    selectedInstallerAddress() {
      return this.$store.getters.getSelectedInstaller.company_data
        .company_address
        ? this.$store.getters.getSelectedInstaller.company_data.company_address
            .street +
            ' ' +
            this.$store.getters.getSelectedInstaller.company_data
              .company_address.street_number +
            ', ' +
            this.$store.getters.getSelectedInstaller.company_data
              .company_address.zip +
            ' ' +
            this.$store.getters.getSelectedInstaller.company_data
              .company_address.city
        : '';
    },
    userPersonalData() {
      return this.$store.getters.getUserId;
    },
    translateInstallerStatus() {
      let translateInstallerStatus = [];
      if (this.selectedInstaller.status === 'LICENSE_REQUEST_CREATED') {
        return (translateInstallerStatus = ['neu', '#d79bb8']);
      }
      if (this.selectedInstaller.status === 'LICENSE_REQUEST_CHECKING') {
        return (translateInstallerStatus = ['in Prüfung', '#CB80A2']);
      }
      if (this.selectedInstaller.status === 'LICENSE_REQUEST_ACTIVE') {
        return (translateInstallerStatus = ['aktiv', '#a72f62']);
      }
      if (this.selectedInstaller.status === 'LICENSE_REQUEST_REFUSED') {
        return (translateInstallerStatus = ['Prüfung abgelehnt', '#525252']);
      }
      if (this.selectedInstaller.status === 'LICENSE_REQUEST_EXPIRED') {
        return (translateInstallerStatus = ['inaktiv', '#767676']);
      }
      if (this.selectedInstaller.status === 'LICENSE_REQUEST_DELETED') {
        return (translateInstallerStatus = ['gelöscht', '#767676']);
      }
      return translateInstallerStatus;
    },
  },
  methods: {
    getInstallerFromBackend(id) {
      getInstallerById(id)
        .then((res) => {
          const currentInstaller = res.getInstallerById;
          this.$store.commit('setSelectedInstaller', currentInstaller);
        })
        .catch((err) => {
          console.log(err);
          this.isServerError = true;
        });
    },
    goOnePageBack() {
      const previousPage = this.$store.getters.getPreviousPage;
      if (previousPage) {
        this.$router.push(previousPage);
      } else {
        this.$router.push('/');
      }
    },
    showModal(data) {
      this.currentComponent = data.component;
      this.backendMethod = data.method;
      this.componentProperties = data.properties ? data.properties : {};

      document.body.classList.add('modal-open');
      this.disableScrolling();
      this.noteValue.note = '';
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
      this.updateNoteChangedObject('');
      this.isModalVisible = true;
      this.showSpinner = false;
      this.showModalUpdateError = false;
    },
    closeModal() {
      // delete changed_object values in vuex
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
      this.noteValue.note = '';
      this.updateNoteChangedObject('');
      if (this.currentComponent === 'ResponisibleSpecialistsStepperComponent') {
        this.$store.commit('setNewResponsibleSpecialistAdded', false);
        this.$store.commit('setIndexResponsibleSpecialist', null);
      }
      document.body.classList.remove('modal-open');
      this.enableScrolling();
      this.isModalVisible = false;
      this.alert = false;
      this.showSpinner = false;
      this.showModalUpdateError = false;
    },
    showExpireDialog() {
      this.disableScrolling();
      this.isExpireDialogVisible = true;
    },
    closeExpireDialog(doExpire) {
      if (doExpire) {
        this.changeStatus('LICENSE_REQUEST_EXPIRED');
      }
      this.enableScrolling();
      this.isExpireDialogVisible = false;
    },
    updateNoteChangedObject(value) {
      this.$store.commit('updateNoteChangedObject', value);
    },
    closeModalAndSaveData() {
      if (this.$store.getters.getCurrentComponentStatus === 'finished') {
        this.showSpinner = true;
        if (
          isEqual(
            this.$store.getters.getChangedObject,
            this.$store.getters.getOldValueObject
          ) &&
          (this.backendMethod !== 'updateInstallerFilesName' ||
            (this.backendMethod === 'updateInstallerFilesName' &&
              !this.$store.getters.getInstallerFileChanged))
        ) {
          EventBus.$emit(`show-same-data-errors-${this.currentComponent}`);
          this.showSpinner = false;
          this.showModalUpdateError = false;
        } else {
          switch (this.backendMethod) {
            case 'updateInstallerFilesName':
              EventBus.$emit('start-installer-file-upload');
              break;
            default:
              break;
          }
          const requestBody = {
            id: this.selectedInstaller.id,
            user_id: this.selectedInstaller.user_id,
            new_value: this.$store.getters.getChangedObject,
            old_value: this.$store.getters.getOldValueObject,
            note: this.$store.getters.getNoteChangedObject,
          };
          this.uploadChangesToBackend(requestBody);
        }
      } else {
        EventBus.$emit(`show-errors-${this.currentComponent}`);
      }
    },
    disableScrolling() {
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y');
      document.getElementById('installer-details').style.zIndex = 1;
      const body = document.body;
      body.style.position = 'fixed';
      body.style.width = '-webkit-fill-available';
      body.style.top = `-${scrollY}`;
    },
    enableScrolling() {
      document.getElementById('installer-details').style.zIndex = 0;
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    uploadChangesToBackend(changedObject) {
      userService[this.backendMethod](changedObject, this.userPersonalData)
        .then(() => {
          document.body.classList.remove('modal-open');
          this.enableScrolling();
          this.noteValue.note = '';
          this.isModalVisible = false;
          this.showSpinner = false;
          this.showModalUpdateError = false;
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.alert = true;
            this.showSpinner = false;
            this.showModalUpdateError = true;
          }, 3000);
        });
    },
    getChangedData() {
      const currentInstaller = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedInstaller)
      );
      const status = { status: currentInstaller.status };
      return status;
    },
    getChangedValue(valueName, responseObject) {
      let obj = null;
      if (responseObject[valueName]) {
        obj = JSON.parse(responseObject[valueName]);
      } else if (responseObject.workflow_payload) {
        const tmp = JSON.parse(responseObject.workflow_payload);
        valueName === 'new_value'
          ? (obj = { status: tmp.new_status })
          : (obj = { status: tmp.old_status });
      }
      return obj;
    },
    changeStatus(status) {
      this.changedObject = this.getChangedData();
      this.$store.commit('setChangedObject', {
        status,
      });
      this.$store.commit(
        'setOldValueObject',
        JSON.parse(JSON.stringify(this.changedObject))
      );

      const requestBody = {
        id: this.selectedInstaller.id,
        user_id: this.selectedInstaller.user_id,
        new_value: this.$store.getters.getChangedObject,
        old_value: this.$store.getters.getOldValueObject,
      };
      userService
        .updateInstallerConcessionStatus(requestBody, this.userPersonalData)
        .then((res) => {
          if (res.updateInstallerConcessionStatus) {
            this.$store.commit(
              'updateSelectedInstallerData',
              JSON.parse(res.updateInstallerConcessionStatus.new_value)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        }),
        this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
    },
  },
  apollo: {
    $subscribe: {
      tags: {
        query: gql`
          subscription onInstallerHistoryChanged($orderId: String) {
            onInstallerHistoryChanged(id: $orderId) {
              id
              changed_by
              event_origin
              created
              field
              title
              old_value
              new_value
              workflow_payload
              note
              status
              filename
            }
          }
        `,
        variables() {
          return {
            orderId: this.installer_id,
          };
        },
        result({ data }) {
          const payload = {
            id: data.onInstallerHistoryChanged.id,
            new_value: this.getChangedValue(
              'new_value',
              data.onInstallerHistoryChanged
            ),
            old_value: this.getChangedValue(
              'old_value',
              data.onInstallerHistoryChanged
            ),
            title: data.onInstallerHistoryChanged.title,
            note: data.onInstallerHistoryChanged.note,
            filename: data.onInstallerHistoryChanged.filename,
            status: JSON.parse(data.onInstallerHistoryChanged.status),
          };
          data.onInstallerHistoryChanged.payload = JSON.stringify(payload);
          payload.new_value
            ? this.$store.commit(
                'updateSelectedInstallerData',
                payload.new_value
              )
            : '';
          this.$store.commit(
            'updateLastChangedByInSelectedInstaller',
            data.onInstallerHistoryChanged.changed_by
          );
          this.$store.commit(
            'updateLastUpdateInSelectedInstaller',
            data.onInstallerHistoryChanged.created
          );
          this.$store.commit('setNotifyEvent', data.onInstallerHistoryChanged);
          EventBus.$emit(
            'update-history-table-installer',
            data.onInstallerHistoryChanged
          );
        },
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('resetInstallerFileAndFileURLs');
    next();
  },
};
</script>

<style scoped lang="scss">
.page_title {
  font-size: 60px;
}
.card-without-title > .frosted_glass_card_title {
  margin-bottom: 0px !important;
}
.header_container {
  display: flex;
  align-items: center;
}
.item_container {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.company_data {
  font-size: 25px;
}
.company_address {
  font-size: 20px;
}
.back-button {
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0;
  height: 35px !important;
  width: 90px;
  box-shadow: none;
  margin-right: 30px;
}

.status-button {
  color: white !important;
  box-shadow: none;
  pointer-events: none;
}

.inspection-button {
  width: 40%;
  justify-content: flex-end;
  display: flex;
}

.z-index-0 {
  z-index: 0;
}
</style>
