<template>
  <div>
    <div class="history-header-container">
      <div class="history-content-container">
        <div v-if="!isNoteUploadInfo">
          <NEWNoteUploadComponent
            @send-note-and-uploadfile="sendNoteAndFile($event)"
            @note-data-deleted="deleteNote = false"
            :deleteNote="deleteNote"
            :labels="noteLabels"
            :showNoteContainerStyle="false"
            :fileUploadColor="'white'"
            class="mb-2"
          >
            <template v-slot:status-alert>
              <v-alert
                class="alert_success"
                style="background-color: #e4f0d6 !important"
                v-if="alert"
                max-width="270px"
                max-height="35px"
                text
                transition="scale-transition"
              >
                <template v-slot:append>
                  <v-icon color="green" class="ml-2">mdi-check</v-icon>
                </template>
                Erfolgreich gespeichert
              </v-alert>
              <v-alert
                type="error"
                class="alert_error mt-4"
                max-width="270px"
                v-if="alertError"
                text
                transition="scale-transition"
              >
                Speicherung fehlgeschlagen
              </v-alert>
              <div
                class="error-dialog"
                style="margin-top: 10px"
                v-if="showErrors"
              >
                {{ $t('installation_address.inputs_error') }}
              </div>
            </template>
          </NEWNoteUploadComponent>
        </div>
        <div v-else class="container--note-upload-pending">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="60"
            :width="5"
          />
          <NewAlert
            class="mt-4"
            color="INFO"
            :message="$t('emobility_inspection.info_file_upload')"
          />
        </div>
      </div>
    </div>

    <div class="list-group-wrapper">
      <transition name="fade">
        <div class="loading" v-show="loading">
          <v-progress-circular
            indeterminate
            :size="75"
            color="#9B004B"
          ></v-progress-circular>
        </div>
      </transition>
      <div class="list-group" id="infinite-list">
        <HistoryComponentScroll
          :historyType="historyType"
          :showClerc="true"
          v-for="(historyItem, index) in historyData"
          :key="index"
          :historyItem="historyItem"
          @update-show-details="historyItem.show_details = $event"
        >
        </HistoryComponentScroll>
      </div>
    </div>
  </div>
</template>

<script>
import { HistoryComponentScroll } from '@newnetzportal/netzportal-webapp-storybook-components';
import orderService from '../../services/OrderService';
import { uploadFile } from '@/services/FileUploadService';
import noteService from '../../services/NoteService';
import DocumentsService from '../../services/DocumentsService';
import {
  transformRawDataToHistoryTableFormat,
  mapEventNameToTitle,
} from './transform_raw_data_to_history_table_format';
import { EventBus } from '@/main';
import { emptyEventData } from '@/store/ordersModule';
import { NEWNoteUploadComponent } from '@newnetzportal/netzportal-webapp-storybook-components';
import { NewAlert } from '@bit/new-netzportal.netzportal.alert';
import { getTranslations } from '@/helpers/globalFunctions';
import { mixinData } from '@/helpers/handleJSONPathKeys';

export default {
  name: 'HistoryTable',
  components: {
    HistoryComponentScroll,
    NEWNoteUploadComponent,
    NewAlert,
  },
  props: {
    historyType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      allChanges: [],
      selectedOrder: this.$store.getters.getSelectedOrder,
      selectedInstaller: this.$store.getters.getSelectedInstaller,
      selectedEmobility: this.$store.getters.getSelectedEmobility,
      selectedPhotovoltaic: this.$store.getters.getSelectedPhotovoltaic,
      loading: false,
      all_data_loaded: false,
      alert: false,
      alertError: false,
      resetNoteComponent: false,
      showErrors: false,
      order_id: this.$route.params.id ? this.$route.params.id : '',
      isNoteUploadInfo: false,
      noteLabels: getTranslations('note_upload'),
      deleteNote: false,
    };
  },
  created() {
    EventBus.$on(
      'update-history-table-' + this.historyType.toLowerCase(),
      (event) => {
        this.allChanges.push(event);
        if (this.allChanges.length > 0) {
          const sortedActivities = this.allChanges.sort(
            (a, b) => new Date(b.created) - new Date(a.created)
          );
          this.historyData = sortedActivities
            .filter(
              (item) =>
                ![
                  'updateInstallerBadgeId',
                  'updateOrderCaseNumber',
                  'updateEmobilityCaseNumber',
                  'updatePhotovoltaicCaseNumber',
                  'addOrderFileHistoryEntry',
                  'addEmobilityFileHistoryEntry',
                  'addInstallerFileHistoryEntry',
                  'addPhotovoltaicFileHistoryEntry',
                ].includes(item.field)
            )
            .map(this.tableHistoryMapper);
        }
      }
    );
    this.getHistoryFromBackend(true);
    this.$store.commit('setLastUpdatedComponent', '');
  },
  mounted() {
    // Detect when scrolled to bottom.
    const listElm = document.querySelector('#infinite-list');
    listElm.addEventListener('scroll', () => {
      if (
        listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight &&
        !this.all_data_loaded
      ) {
        this.getHistoryFromBackend();
      }
    });
  },
  watch: {
    notifyEvent(newValue) {
      if (
        this.lastUpdatedComponent === 'historyTable' &&
        (newValue.field === 'addOrderNote' ||
          newValue.field === 'addInstallerNote' ||
          newValue.field === 'addEmobilityNote')
      ) {
        this.alert = true;
        setTimeout(() => {
          (this.alert = false),
            this.$store.commit('setNotifyEvent', emptyEventData);
        }, 3000);
      }
    },
  },
  computed: {
    historyData: {
      get() {
        return this.$store.getters['get' + this.historyType + 'HistoryData'];
      },
      set(value) {
        this.$store.commit(`set${this.historyType}HistoryData`, value);
      },
    },
    notifyEvent() {
      return this.$store.getters.getNotifyEvent
        ? this.$store.getters.getNotifyEvent
        : emptyEventData;
    },
    lastUpdatedComponent() {
      return this.$store.getters.getLastUpdatedComponent;
    },
    user_id() {
      return this.$store.getters.getUserId;
    },
  },
  methods: {
    getHistoryFromBackend(first_load) {
      this.loading = true;
      if (first_load) {
        this.allChanges = [];
      }
      const params = {
        id: this.order_id,
        from_index: this.allChanges.length,
        limit: 20,
      };
      orderService['get' + this.historyType + 'History'](params)
        .then((res) => {
          const responseChanges =
            res[this.historyType.toLowerCase() + 'History'].changes;
          responseChanges
            ? (this.allChanges = this.allChanges.concat(responseChanges))
            : '';
          this.historyData = this.allChanges
            .filter((item) => item.field !== 'updateInstallerBadgeId')
            .map(this.tableHistoryMapper);
          if (
            res[this.historyType.toLowerCase() + 'History'].count_changes ===
            this.allChanges.length
          ) {
            this.all_data_loaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableHistoryMapper(change) {
      const payload = JSON.parse(change.payload);
      if (
        change.field === 'notifyOrderStatus' ||
        ((change.field === 'updateOrderStatus' ||
          change.field === 'updatePVStatus' ||
          change.field === 'updateInstallerConcessionStatus' ||
          change.field === 'updateEmobilityStatus') &&
          payload.old_value.status)
      ) {
        return {
          date: change.created,
          clerk: change.changed_by,
          old_status: payload.old_value.status,
          new_status: payload.new_value.status,
          show_details: false,
          field: change.field,
        };
      }
      if (change.field === 'updateOrderSAPKeys') {
        return {
          date: change.created,
          change_title: mapEventNameToTitle(change, payload.title),
          clerk: change.changed_by,
          new_value: null,
          old_value: transformRawDataToHistoryTableFormat(
            payload.new_value,
            this.selectedOrder.product
          ),
          show_details: false,
          field: change.field,
        };
      }
      if (
        (change.field === 'addEmobilityNote' ||
          change.field === 'addOrderNote' ||
          change.field === 'addInstallerNote') &&
        payload.filename
      ) {
        return {
          date: change.created,
          change_title: mapEventNameToTitle(change, payload.title),
          clerk: change.changed_by,
          note: payload.note,
          new_value: null,
          old_value: transformRawDataToHistoryTableFormat({
            note_file: payload.filename,
          }),
          show_details: false,
          field: change.field,
        };
      }
      if (
        change.field === 'updateOrderSAPError' ||
        change.field === 'notifyOrderSAPError'
      ) {
        return {
          date: change.created,
          change_title: mapEventNameToTitle(change, payload.title),
          clerk: change.changed_by,
          new_value: transformRawDataToHistoryTableFormat(
            payload.new_value,
            this.selectedOrder.product
          ),
          old_value: payload.old_value.error,
          show_details: false,
          field: change.field,
        };
      }
      if (
        change.field === 'updateTechnicalInspectionData' ||
        change.field === 'updateInstallerInspectionData' ||
        change.field === 'updateEmobilityInspectionData' ||
        change.field === 'renewInstallerConcession'
      ) {
        change.status = payload.status;
        return {
          date: change.created,
          change_title: mapEventNameToTitle(change, payload.title),
          clerk: change.changed_by,
          status: payload.status,
          old_status: payload.old_value.status,
          new_status: payload.new_value.status,
          show_details: false,
          field: change.field,
        };
      } else if (
        change.field === 'createEMobilityRequest' &&
        payload.derived_from_order
      ) {
        return {
          date: change.created,
          change_title: mapEventNameToTitle(change, payload.title),
          clerk: change.changed_by,
          old_value: transformRawDataToHistoryTableFormat(
            payload,
            this.selectedOrder.product
          ),
          new_value: null,
          show_details: false,
          field: change.field,
        };
      } else {
        if (change.field === 'updatePVCountersData') {
          // Use the mixinData function to apply JSONPath updates
          payload.old_value = mixinData(payload.old_value);
          payload.new_value = mixinData(payload.new_value);
        }
        const productName =
          this.historyType === 'Order'
            ? this.selectedOrder.product
            : this.selectedInstaller.concession_division;
        const object = {
          date: change.created,
          change_title: mapEventNameToTitle(change, payload.title),
          note: payload.note,
          clerk: change.changed_by,
          old_value: payload.old_value
            ? transformRawDataToHistoryTableFormat(
                payload.old_value,
                productName ? productName : '',
                change.field
              )
            : null,
          new_value: payload.new_value
            ? transformRawDataToHistoryTableFormat(
                payload.new_value,
                productName ? productName : '',
                change.field
              )
            : null,
          show_details: false,
          field: change.field,
        };
        return object;
      }
    },
    async sendNoteAndFile(value) {
      this.isNoteUploadInfo = true;
      const noteObj = {
        id: this.order_id,
        note: value.note,
        title: value.title,
        filename: value.filename,
        user_id: this['selected' + this.historyType].user_id,
      };
      this.$store.commit('setLastUpdatedComponent', 'historyTable');
      if (value.note) {
        try {
          const userResponse = await noteService[
            'add' + this.historyType + 'Note'
          ](noteObj, this.user_id);

          // Has to get the "_persistent_event_id_" from Response; used for s3-subfoldering
          if (value.filename) {
            const url = (
              await DocumentsService[
                'put' + this.historyType + 'DocumentGraphQL'
              ](
                `${this.order_id}/${
                  userResponse['add' + this.historyType + 'Note']
                    ._persistent_event_id_
                }/${value.filename}`,
                process.env.VUE_APP_STAGE
              )
            ).uploadFile;
            await uploadFile(url, value.file);
            this.resetNoteComponent = true;
          }
          this.showErrors = false;
          this.resetNoteComponent = true;
          this.isNoteUploadInfo = false;
        } catch (err) {
          console.error(err);
          this.isNoteUploadInfo = false;
          this.alertErrorNote = true;
        }
      } else {
        this.showErrors = true;
      }
    },
  },
};
</script>

<style lang="scss">
$medium-font-family: 'DIN Next LT W04 Medium', Roboto, -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

.change-title {
  font-family: $medium-font-family;
}

.note-text {
  width: 400px;
}

.loading {
  text-align: center;
  position: absolute;
  z-index: 9;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.list-group {
  overflow: auto;
  max-height: 50vh;
}
.alert_success {
  display: flex !important;
  margin-top: 10px;
}

.history-header-container {
  display: flex;
  color: #6e6e6e;
}

.history-content-container {
  border-radius: 8px 8px 0px 0px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1px;
  min-height: 48px;
  padding: 16px;
  width: 100%;
}
</style>
