<template>
  <div>
    <!-- Blindleistungsart -->
    <RowItem
      v-if="feedInSystem.reactive_power_type"
      first
      class="row-item"
      :title="$t('feed_in_system_details.reactive_power_type')"
      :editable="false"
    >
      <div v-html="feedInSystem.reactive_power_type"></div>
    </RowItem>
    <!-- Spannungsebene Kundenstation -->
    <!--     <RowItem
      class="row-item"
      :title="$t('feed_in_system_details.voltage_level_customer_station')"
      :editable="true"
    >
      DUMMY DATA
      <br />
      Dummy Data
    </RowItem> -->
    <!-- Messlokation -->
    <RowItem
      class="row-item"
      :first="!feedInSystem.reactive_power_type"
      field="updatePVInstallationAddress"
      :title="`<strong>${$t(
        'feed_in_system_details.measurement_location'
      )}</strong> <br> ${$t(
        'feed_in_system_details.measurement_location_fields'
      )}`"
      :editable="false"
      @edit="
        $emit('edit-component', {
          component: 'dummy component name',
          location: 'MeasurementData',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.measurementLocation"></div>
    </RowItem>
    <!-- Marktlokation -->
    <RowItem
      last
      class="row-item"
      field="updatePVInstallationAddress"
      :title="`<strong>${$t(
        'feed_in_system_details.market_location'
      )}</strong><br>${$t('feed_in_system_details.market_location_fields')}`"
      :editable="false"
      @edit="
        $emit('edit-component', {
          component: 'dummy component name',
          location: 'MeasurementData',
          properties: feedInSystem,
        })
      "
    >
      <div v-html="presentableFeedInSystem.marketLocation"></div>
    </RowItem>
  </div>
</template>

<script>
import RowItem from './RowItem';
import { formatPVObject } from './transform_raw_data_to_pv_format';
import { PVJOURNEYS } from '@/helpers/constants';

export default {
  name: 'MeasurementData',
  components: {
    RowItem,
  },
  props: {
    feedInSystem: Object,
  },
  computed: {
    presentableFeedInSystem() {
      return formatPVObject(this.feedInSystem);
    },
    isPVInbetriebnahme() {
      return this.feedInSystem.journey === PVJOURNEYS.pvi;
    },
    isPVAnfrage() {
      return this.feedInSystem.journey === PVJOURNEYS.pv;
    },
  },
};
</script>

<style scoped>
.row-item {
  margin-block: 1px;
}
</style>
