<template>
  <div>
    <div
      v-if="
        selectedFeedInSystem && Object.keys(selectedFeedInSystem).length !== 0
      "
    >
      <div
        class="warning_message_notify_changes"
        v-if="mustBeNotifiedOfChangedData"
      >
        <div style="width: 380px">
          {{ $t('feed_in_system_details.new_changes_warning') }}
        </div>
        <v-btn
          v-if="!spinnerEmailNotification"
          @click="triggerDeliverPVConfirmation"
          color="primary"
        >
          {{ $t('feed_in_system_details.notify_user_btn_label') }}
        </v-btn>
        <v-progress-circular
          v-if="spinnerEmailNotification"
          indeterminate
          color="primary"
          :size="60"
          :width="5"
        />
      </div>
      <v-alert
        class="alert_success_notify_changes"
        style="background-color: #e4f0d6 !important"
        :value="true"
        max-height="35px"
        text
        transition="scale-transition"
        v-if="showAlertSuccessNotifyChanges"
      >
        <template v-slot:append>
          <v-icon color="green" class="ml-2">mdi-check</v-icon>
        </template>

        {{ $t('feed_in_system_details.new_changes_email_sent') }}
      </v-alert>
      <v-alert
        type="error"
        class="alert_error_notify_changes"
        style="background-color: #ffe8e6 !important; display: flex !important"
        text
        transition="scale-transition"
        dismissible
        v-if="showAlertErrorNotifyChanges"
        @input="handleAlertClose"
      >
        {{ $t('feed_in_system_details.new_changes_error') }}
      </v-alert>

      <!-- HeaderDetails -->
      <NewGlassCard class="card-without-title mb-6" title="">
        <div class="header_container_new">
          <v-btn class="back-button" @click="goOnePageBack()">
            <v-icon color="#6E6E6E">mdi-arrow-left</v-icon>
          </v-btn>
          <div class="connection_container">
            <div class="connection_client">
              Kunde: <span v-html="selectedFeedInSystemApplicant" />
            </div>
            <div class="connection_location">
              <div v-html="'Bauort: ' + selectedFeedInSystemAddress"></div>
            </div>
          </div>
          <v-btn class="contact_button" @click="startCall()">
            <v-icon color="white" x-large>mdi-phone</v-icon>
          </v-btn>
          <v-btn class="contact_button" @click="sendMail()">
            <v-icon color="white" x-large>mdi-email</v-icon>
          </v-btn>
          <v-btn
            rounded
            :style="{
              color:
                selectedFeedInSystem.status !== FEED_IN_STATUSES.cancelled.value
                  ? 'white'
                  : 'black',
              boxShadow: 'none',
              pointerEvents: 'none',
            }"
            class="ml-2 mr-2"
            :color="orderStatusConfig[1]"
          >
            {{ orderStatusConfig[0] }}
          </v-btn>
          <div
            style="
              width: 40%;
              justify-content: flex-end;
              display: flex;
              gap: 12px;
            "
          >
            <v-btn
              class="cancel_btn"
              @click="onClickCancelOrder"
              v-if="showCancelOrderBtn"
            >
              <span style="font-weight: 700">
                {{ $t('feed_in_system_details.cancel_order') }}
              </span>
            </v-btn>
            <v-btn
              v-show="
                selectedFeedInSystem.status ===
                  FEED_IN_STATUSES.cancelled.value ||
                selectedFeedInSystem.status === FEED_IN_STATUSES.declined.value
              "
              class="mr-4"
              @click="onClickReactivateCancelledOrDeclinedOrder"
              color="primary"
            >
              {{ $t('feed_in_system_details.reactivate_order') }}
            </v-btn>
            <v-btn
              v-show="showStartTechnicalInspectionButton"
              class="mr-4"
              @click="updateTechnicalInspectionStatus"
              color="primary"
            >
              {{ $t('feed_in_system_details.start_inspection') }}
            </v-btn>
          </div>
        </div>
      </NewGlassCard>

      <!-- Technische Prüfung -->
      <NewGlassCard
        v-if="showTechnicalInspection"
        class="default-mb"
        :title="$t('technical_inspection.title')"
      >
        <FeedInTechnicalInspection />
      </NewGlassCard>

      <div :class="this.$vuetify.breakpoint.xl ? 'xl-design-details-page' : ''">
        <div>
          <!-- Antragsdaten -->
          <NewGlassCard
            id="contract-details"
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('feed_in_system_details.order_details')"
          >
            <ContractDetails
              :feedInSystem="selectedFeedInSystem"
              @edit-component="editComponent($event)"
            />
          </NewGlassCard>
          <!-- Kundenfragen (XL-Design)-->
          <NewGlassCard
            id="pv-order-questions"
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb ml-2'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('pv_order_questions.title')"
            v-if="userQuestionsExist && this.$vuetify.breakpoint.xl"
          >
            <OrderQuestions :orderQuestionsArray="pvOrderQuestionsArray" />
          </NewGlassCard>
        </div>

        <div>
          <!-- Technische Details -->
          <NewGlassCard
            id="technical-details"
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('feed_in_system_details.technical_details')"
          >
            <TechnicalDetails
              :feedInSystem="selectedFeedInSystem"
              @edit-component="editComponent($event)"
            />
          </NewGlassCard>

          <!-- Messstellendaten -->
          <NewGlassCard
            id="measurement-data"
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('feed_in_system_details.measurement_data')"
          >
            <MeasurementData
              :feedInSystem="selectedFeedInSystem"
              @edit-component="editComponent($event)"
            />
          </NewGlassCard>
          <!-- Kundenfragen (kleiner als das XL-Design)-->
          <NewGlassCard
            id="pv-order-questions"
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb ml-2'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('pv_order_questions.title')"
            v-if="userQuestionsExist && !this.$vuetify.breakpoint.xl"
          >
            <OrderQuestions :orderQuestionsArray="pvOrderQuestionsArray" />
          </NewGlassCard>
        </div>
      </div>
      <div></div>

      <div :class="this.$vuetify.breakpoint.xl ? 'xl-design-details-page' : ''">
        <!-- Dokumentenhistorie -->
        <div>
          <NewGlassCard
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb ml-2'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('document_history.title')"
          >
            <DocumentHistoryComponent :historyType="'Photovoltaic'" />
          </NewGlassCard>
        </div>

        <!-- HistoryTable -->
        <div>
          <NewGlassCard
            :class="
              this.$vuetify.breakpoint.xl
                ? 'xl-design-details-page-element default-mb ml-2'
                : 'default-mb'
            "
            class="z-index-0"
            :title="$t('history.title')"
          >
            <HistoryTable :historyType="'Photovoltaic'" />
          </NewGlassCard>
        </div>
      </div>

      <NewModalDialog
        v-if="isModalVisible"
        :value="noteValue"
        role="dialog"
        :enable-note="enableNote"
        @update-note-data="updateNoteChangedObject"
        @cancel-data="closeModal"
      >
        <template v-slot:body>
          <DynamicComponent
            class="feed-in-systems-form"
            :type="currentModalComponent"
            :componentProperties="currentModalComponentProps"
          />
          <div
            v-if="showModalUpdateError"
            style="width: fit-content"
            class="error-dialog ml-8"
          >
            {{ $t('feed_in_system_details.saving_failed_please_reload') }}
          </div>
        </template>
        <template v-slot:footer>
          <div v-show="showSpinner" class="update-event-spinner">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="60"
              :width="5"
            />
          </div>
          <div v-if="!showSpinner">
            <button type="button" class="btn-save" @click="submitComponent">
              {{ $t('feed_in_system_details.save') }}
            </button>
            <button type="button" class="btn-cancel" @click="closeModal">
              {{ $t('feed_in_system_details.cancel') }}
            </button>
          </div>
          <v-alert
            type="error"
            class="alert_error ml-4"
            v-if="alert"
            text
            transition="scale-transition"
          >
            {{ $t('feed_in_system_details.saving_failed') }}
          </v-alert>
        </template>
      </NewModalDialog>
    </div>
    <div v-if="serverError">
      <h1>{{ $t('order_details.loading_error') }}</h1>
      <p>{{ $t('order_details.error_hint') }}</p>
    </div>
  </div>
</template>

<script>
// Components
import {
  NewGlassCard,
  NewModalDialog,
} from '@newnetzportal/netzportal-webapp-storybook-components';
import HistoryTable from '../components/history-table/HistoryTable';
import DocumentHistoryComponent from '../components/order-details/DocumentHistoryComponent';
import ContractDetails from '@/components/feed-in-system-details/ContractDetails';
import TechnicalDetails from '@/components/feed-in-system-details/TechnicalDetails';
import MeasurementData from '@/components/feed-in-system-details/MeasurementData';
import DynamicComponent from '../components/feed-in-system-steps-components/DynamicDialogBody';
import FeedInTechnicalInspection from '../components/feed-in-system-details/FeedInTechnicalInspection';
// Services && Helpers
import { EventBus } from '@/main';
import gql from 'graphql-tag';
import {
  getPhotovoltaicById,
  updatePVStatus,
  deliverPVConfirmation,
} from '@/services/FeedInSystemsService';
import {
  installationAddressObject,
  personalDataObject,
} from '@/helpers/transformToPresentableData';
import { initPVFiles } from '@/helpers/PVFiles';
import { translatePVOrderStatus } from '../components/feed-in-system-details/FeedInOrderStatusConfig';
import { FEED_IN_STATUSES } from '../helpers/constants';
import { mapGetters, mapMutations } from 'vuex';
import OrderQuestions from '../components/order-details/OrderQuestions';
import { mixinData } from '@/helpers/handleJSONPathKeys';

export default {
  name: 'FeedInSystemsDetailsPage',
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  components: {
    HistoryTable,
    ContractDetails,
    TechnicalDetails,
    MeasurementData,
    DynamicComponent,
    NewGlassCard,
    NewModalDialog,
    DocumentHistoryComponent,
    FeedInTechnicalInspection,
    OrderQuestions,
  },
  data() {
    return {
      enableNote: true,
      FEED_IN_STATUSES: FEED_IN_STATUSES,
      photovoltaic_id: this.$route.params.id ?? '',
      isModalVisible: false,
      noteValue: { note: '' },
      currentModalComponent: null,
      currentModalComponentProps: null,
      alert: false,
      serverError: false,
      spinnerEmailNotification: false,
      showAlertSuccessNotifyChanges: false,
      showAlertErrorNotifyChanges: false,
      notificationWhitelist: [
        FEED_IN_STATUSES.created?.value,
        FEED_IN_STATUSES.critical_created?.value,
        FEED_IN_STATUSES.confirmed?.value,
        FEED_IN_STATUSES.in_impl?.value,
        FEED_IN_STATUSES.in_impl_insp?.value,
        FEED_IN_STATUSES.in_impl_conf?.value,
      ],
      cancelOrderWhiteList: [
        FEED_IN_STATUSES.created?.value,
        FEED_IN_STATUSES.confirmed?.value,
        FEED_IN_STATUSES.in_impl?.value,
        FEED_IN_STATUSES.in_impl_insp?.value,
        FEED_IN_STATUSES.in_impl_conf?.value,
      ],
    };
  },
  async created() {
    this.handleScrollPosition();
    if (this.photovoltaic_id) {
      await this.getPhotovoltaicFromBackend(this.photovoltaic_id);
    }
  },
  computed: {
    ...mapGetters(['getShowConfirmationDialog']),
    showCancelOrderBtn() {
      if (
        this.notificationWhitelist.includes(this.selectedFeedInSystem.status)
      ) {
        return true;
      }
      return false;
    },
    pendingNavigation() {
      return this.$store.state.pendingNavigation;
    },
    mustBeNotifiedOfChangedData() {
      return this.$store.state.mustBeNotifiedOfChangedData;
    },
    selectedFeedInSystem: {
      get() {
        return this.$store.getters.getSelectedFeedInSystem;
      },
      set(newValue) {
        this.$store.commit('setSelectedFeedInSystem', newValue);
      },
    },
    showSpinner: {
      get() {
        return this.$store.getters.getShowSpinnerInModal;
      },
      set(value) {
        this.$store.commit('setShowSpinnerInModal', value);
      },
    },
    showModalUpdateError: {
      get() {
        return this.$store.getters.getShowModalUpdateError;
      },
      set(value) {
        this.$store.commit('setShowModalUpdateError', value);
      },
    },
    currentOrderStatus() {
      return this.selectedFeedInSystem.status;
    },
    selectedFeedInSystemApplicant() {
      return personalDataObject(this.selectedFeedInSystem.applicant);
    },
    selectedFeedInSystemAddress() {
      return installationAddressObject(this.selectedFeedInSystem);
    },
    emailSubject() {
      return 'subject';
    },
    orderStatusConfig() {
      return translatePVOrderStatus(this.selectedFeedInSystem.status);
    },
    showTechnicalInspection() {
      return (
        this.selectedFeedInSystem.status === FEED_IN_STATUSES.tech_feas.value ||
        this.selectedFeedInSystem.status ===
          FEED_IN_STATUSES.in_impl_insp.value ||
        this.selectedFeedInSystem.status ===
          FEED_IN_STATUSES.fulfilled_insp.value
      );
    },
    showStartTechnicalInspectionButton() {
      return (
        this.selectedFeedInSystem.status === FEED_IN_STATUSES.created.value ||
        this.selectedFeedInSystem.status ===
          FEED_IN_STATUSES.critical_created.value ||
        this.selectedFeedInSystem.status === FEED_IN_STATUSES.in_impl.value ||
        this.selectedFeedInSystem.status === FEED_IN_STATUSES.fulfilled.value
      );
    },
    userQuestionsExist() {
      return (
        this.selectedFeedInSystem.pv_request_questions ||
        this.selectedFeedInSystem.pv_impl_questions ||
        this.selectedFeedInSystem.pv_impl_protocol_questions
      );
    },
    pvOrderQuestionsArray() {
      const questions = [];
      if (this.selectedFeedInSystem.pv_request_questions) {
        questions.push({
          title: this.$t('feed_in_systems.product_title.photovoltaik'),
          questions: this.selectedFeedInSystem.pv_request_questions,
        });
      }
      if (this.selectedFeedInSystem.pv_impl_questions) {
        questions.push({
          title: this.$t('feed_in_systems.product_title.pv-inbetriebnahme'),
          questions: this.selectedFeedInSystem.pv_impl_questions,
        });
      }
      if (this.selectedFeedInSystem.pv_impl_protocol_questions) {
        questions.push({
          title: this.$t(
            'feed_in_systems.product_title.pv-inbetriebnahme-protokoll'
          ),
          questions: this.selectedFeedInSystem.pv_impl_protocol_questions,
        });
      }
      return questions;
    },
  },
  methods: {
    ...mapMutations([
      'setMustBeNotifiedOfChangedData',
      'setPendingNavigation',
      'setShowConfirmationDialog',
    ]),
    onClickReactivateCancelledOrDeclinedOrder() {
      switch (this.selectedFeedInSystem.status) {
        case FEED_IN_STATUSES.declined.value:
          this.reactivateDeclinedOrder();
          break;
        case FEED_IN_STATUSES.cancelled.value:
          this.reactivateCancelledOrder();
          break;
        default:
          break;
      }
    },
    async reactivateDeclinedOrder() {
      try {
        this.updatePhotovoltaicStatus(FEED_IN_STATUSES.created.value);
      } catch (err) {
        console.log(err);
      }
    },
    async reactivateCancelledOrder() {
      try {
        const changes = this.$store.getters.getPhotovoltaicHistoryData;

        const GetCancelledOrderPreviousStatus = (arr) => {
          for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (
              item.field === 'updatePVStatus' &&
              item.new_status === FEED_IN_STATUSES.cancelled.value
            ) {
              return item.old_status;
            }
          }
          return null;
        };

        const previousStatus = GetCancelledOrderPreviousStatus(changes);

        if (previousStatus) {
          this.updatePhotovoltaicStatus(previousStatus);
        } else {
          console.error('No previous cancelled status found.');
        }
      } catch (err) {
        console.log(err);
      }
    },
    onClickCancelOrder() {
      this.cancelOrder();
    },
    async cancelOrder() {
      try {
        this.updatePhotovoltaicStatus(FEED_IN_STATUSES.cancelled.value);
      } catch (err) {
        console.log(err);
      }
    },
    handleAlertClose() {
      this.showAlertErrorNotifyChanges = false;
    },
    getPhotovoltaicFromBackend(Id) {
      getPhotovoltaicById(Id)
        .then((res) => {
          const currentFeedInSystem = res.getPhotovoltaicById;
          this.selectedFeedInSystem = currentFeedInSystem;
          this.$store.commit('setSelectedPhotovoltaic', currentFeedInSystem);
          this.fetchAndInitFiles();
        })
        .catch((err) => {
          console.log(err);
          this.serverError = true;
        });
    },
    async fetchAndInitFiles() {
      await initPVFiles(this.selectedFeedInSystem);
    },
    goOnePageBack() {
      const previousPage = this.$store.getters.getPreviousPage;
      if (this.mustBeNotifiedOfChangedData) {
        this.setPendingNavigation(previousPage ?? '/');
        this.setShowConfirmationDialog(true);
      } else {
        this.$router.push(previousPage ?? '/');
      }
    },
    startCall() {
      window.open(`tel:${this.selectedFeedInSystem.applicant.phone_number}`);
    },
    sendMail() {
      window.open(
        `mailto:${this.selectedFeedInSystem.applicant.email}?subject=${this.emailSubject}`
      );
    },
    handleScrollPosition() {
      // for Firefox
      window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty(
          '--scroll-y',
          `${window.scrollY}px`
        );
      });
    },
    updateTechnicalInspectionStatus() {
      if (this.photovoltaic_id) {
        this.getPhotovoltaicFromBackend(this.photovoltaic_id);
      }
      switch (this.selectedFeedInSystem.status) {
        case FEED_IN_STATUSES.critical_created.value:
          this.updatePhotovoltaicStatus(FEED_IN_STATUSES.tech_feas.value);
          break;
        case FEED_IN_STATUSES.created.value:
          this.updatePhotovoltaicStatus(FEED_IN_STATUSES.tech_feas.value);
          break;
        case FEED_IN_STATUSES.in_impl.value:
          this.updatePhotovoltaicStatus(FEED_IN_STATUSES.in_impl_insp.value);
          break;
        case FEED_IN_STATUSES.fulfilled.value:
          this.updatePhotovoltaicStatus(FEED_IN_STATUSES.fulfilled_insp.value);
          break;
      }
    },
    updatePhotovoltaicStatus(status) {
      const currentFeedInSystem = JSON.parse(
        JSON.stringify(this.$store.getters.getSelectedFeedInSystem)
      );
      updatePVStatus(
        {
          id: currentFeedInSystem.id,
          old_value: {
            status: currentFeedInSystem.status,
          },
          new_value: {
            status: status,
          },
        },
        this.$store.getters.getUserId
      )
        .then((res) => {
          if (res) {
            const new_status = { status: status };
            this.$store.commit('updateSelectedPhotovoltaicData', new_status);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editComponent(data) {
      this.currentModalComponent = data.component;
      this.currentModalComponentProps = data.properties ? data.properties : {};
      this.enableNote = data.enableNote;
      document.body.classList.add('modal-open');
      this.disableScrolling();
      this.noteValue.note = '';
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
      this.updateNoteChangedObject('');
      this.isModalVisible = true;
      this.$store.commit('setLastUpdatedComponent', data.component);
      this.$store.commit('setLastUpdatedLocation', data.location);
      this.showSpinner = false;
      this.showModalUpdateError = false;
    },
    closeModal() {
      // delete changed_object values in vuex
      this.$store.commit('deleteChangedObject');
      this.$store.commit('deleteOldValueObject');
      this.noteValue.note = '';
      this.updateNoteChangedObject('');

      document.body.classList.remove('modal-open');
      this.enableScrolling();
      this.isModalVisible = false;
      this.showSpinner = false;
      this.showModalUpdateError = false;
    },
    submitComponent() {
      this.showSpinner = true;
      new Promise((resolve, reject) => {
        EventBus.$emit(`submit-${this.currentModalComponent}`, {
          resolve,
          reject,
        });
      })
        .then(() => {
          this.closeModal();

          if (
            this.notificationWhitelist.includes(
              this.selectedFeedInSystem.status
            )
          ) {
            this.setMustBeNotifiedOfChangedData(true);
          }
          this.showSpinner = false;
          this.showModalUpdateError = false;
        })
        .catch(() => {
          setTimeout(() => {
            this.showSpinner = false;
            this.showModalUpdateError = true;
          }, 5000);
        });
    },
    updateNoteChangedObject(value) {
      this.$store.commit('updateNoteChangedObject', value);
    },
    disableScrolling() {
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y');
      document.getElementById('contract-details').style.zIndex = 1;
      const body = document.body;
      body.style.position = 'fixed';
      body.style.width = '-webkit-fill-available';
      body.style.top = `-${scrollY}`;
    },
    enableScrolling() {
      document.getElementById('contract-details').style.zIndex = 0;
      const body = document.body;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    },
    async triggerDeliverPVConfirmation() {
      this.spinnerEmailNotification = true;
      this.showAlertErrorNotifyChanges = false;

      const requestObject = {
        id: this.photovoltaic_id,
      };
      try {
        await deliverPVConfirmation(
          requestObject,
          this.$store.getters.getUserId
        );
        setTimeout(() => {
          this.setMustBeNotifiedOfChangedData(false);
          this.spinnerEmailNotification = false;
          this.showAlertSuccessNotifyChanges = true;

          setTimeout(() => {
            this.showAlertSuccessNotifyChanges = false;
          }, 3000);
        }, 3000);
      } catch (error) {
        this.spinnerEmailNotification = false;
        this.showAlertErrorNotifyChanges = true;
        console.error('Error executing mutation:', error);
      }
    },
    handleBeforeUnload(event) {
      if (this.mustBeNotifiedOfChangedData === true) {
        event.preventDefault();
      }
    },
  },
  apollo: {
    $subscribe: {
      tags: {
        query: gql`
          subscription onPhotovoltaicHistoryChanged($orderId: String) {
            onPhotovoltaicHistoryChanged(id: $orderId) {
              id
              changed_by
              event_origin
              created
              field
              title
              old_value
              new_value
              workflow_payload
              note
              status
              filename
            }
          }
        `,
        variables() {
          return {
            orderId: this.photovoltaic_id,
          };
        },
        result({ data }) {
          const eventData = data.onPhotovoltaicHistoryChanged;
          let oldValueParsed = JSON.parse(eventData.old_value);
          let newValueParsed = JSON.parse(eventData.new_value);

          // Use the mixinData function to apply JSONPath updates
          oldValueParsed = mixinData(oldValueParsed);
          newValueParsed = mixinData(newValueParsed);

          const payload = {
            id: eventData.id,
            new_value: newValueParsed,
            old_value: oldValueParsed,
            title: eventData.title,
            note: eventData.note,
            filename: eventData.filename,
            status: JSON.parse(eventData.status),
          };
          eventData.payload = JSON.stringify(payload);
          newValueParsed
            ? this.$store.commit(
                'updateSelectedPhotovoltaicData',
                newValueParsed
              )
            : '';
          this.$store.commit(
            'updateLastChangedByInSelectedPhotovoltaic',
            eventData.changed_by
          );
          this.$store.commit(
            'updateLastUpdateInSelectedPhotovoltaic',
            eventData.created
          );
          this.$store.commit('setNotifyEvent', eventData);
          EventBus.$emit('update-history-table-photovoltaic', eventData);
        },
      },
    },
  },
  watch: {
    getShowConfirmationDialog(value) {
      if (value) {
        const confirmedNav = confirm(
          `${this.$t('feed_in_system_details.confirm_nav')}`
        );
        this.setShowConfirmationDialog(false);
        if (confirmedNav) {
          if (
            this.pendingNavigation === null ||
            this.pendingNavigation === undefined
          ) {
            console.error('no pendingNavigation found in store');
          } else {
            this.setMustBeNotifiedOfChangedData(false);

            this.$router.push(this.pendingNavigation);
          }
        } else {
          this.setPendingNavigation(null);
        }
      }
    },
    currentOrderStatus(newValue, oldValue) {
      if (
        (oldValue === FEED_IN_STATUSES.tech_feas.value &&
          newValue === FEED_IN_STATUSES.confirmed.value) ||
        (oldValue === FEED_IN_STATUSES.in_impl_insp.value &&
          newValue === FEED_IN_STATUSES.in_impl_conf.value) ||
        (oldValue === FEED_IN_STATUSES.fulfilled_insp.value &&
          newValue === FEED_IN_STATUSES.fulfilled_conf.value)
      ) {
        this.$store.commit('setShowSpinnerInTechnicalInspection', false);
      }
    },
  },
};
</script>

<style>
.z-index-0 {
  z-index: 0 !important;
}

.feed-in-systems-form {
  padding: 0 15px 28px 14px !important;
}

.update-event-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
}

.warning_message_notify_changes {
  color: red;
  background-color: #ffe8e6;
  border-radius: 8px;
  padding: 10px 40px;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  font-weight: bold;
}
.alert_success_notify_changes {
  display: flex !important;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.alert_error_notify_changes {
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}

.cancel_btn {
  border: 1px solid #9b004b;
  border-radius: 8px;
  box-shadow: 0px 2px 1px #00000029;
  color: #9b004b !important;
  font-size: 18px;
  font-weight: 900;
}
</style>
