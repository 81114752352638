import {
  COMPANY_TITLE,
  CITY_TITLE,
  NOT_ARRANGED_YET,
  PLANT_OPERATOR,
  PV_SYSTEM_TYPES,
} from './constants';
import i18n from '../plugins/i18n';
import * as dayjs from 'dayjs';
import * as de from 'dayjs/locale/de';
import { germanNumberFormat } from '@/helpers/globalFunctions';
import {
  type_of_usage_options,
  CURRENT_DIRECTION_LIST,
} from '../components/feed-in-system-details/FeedInTechnicalInspectionConstants';

const newLine = '<br>';

function numberToGermanFormat(value) {
  return germanNumberFormat(value);
}

export function addressDataObject(rawData) {
  return `${rawData.street} ${rawData.street_number}<br>${rawData.zip} ${rawData.city}<br>`;
}

export function personalDataObject(personalData) {
  const legal_title = getLocalizedLegalTitle(personalData.legal_title);
  const title = getFormattedTitle(personalData.title);
  const nameDetails = getNameDetails(personalData);

  return `${legal_title} ${title} ${nameDetails}<br>`;
}

function getLocalizedLegalTitle(legal_title) {
  return legal_title
    ? i18n.t(`contact_data_component.${legal_title}`) + ', '
    : '';
}

function getFormattedTitle(title) {
  if (!title) {
    return '';
  }
  const excludedTitles = ['Divers', 'Keine'];
  return excludedTitles.includes(title) ? '' : title;
}

function getNameDetails(data) {
  switch (data.title) {
    case COMPANY_TITLE:
      return data.company_name;
    case CITY_TITLE:
      return data.city_name;
    default:
      return `${data.name} ${data.surname}`;
  }
}

export function contactDataObject(rawData) {
  const phoneText = `${i18n.t('contact_data_component.phone')}: ${
    rawData.phone_number
  }<br>`;
  const additionalPhoneText = `${i18n.t(
    'order_details.additional_phone_number'
  )}: ${
    rawData.additional_phone_number ? rawData.additional_phone_number : '-'
  }<br>`;
  const LoginNEWMailText = rawData.login_new_email
    ? `${i18n.t('contract_contact.login_new_email')}: ${
        rawData.login_new_email
      }<br>`
    : '';
  const mailText = `${i18n.t('contact_data_component.email')}: ${
    rawData.email
  }<br>`;
  const preferredContactText = `${i18n.t(
    'contact_data_component.preferred_contact_channel'
  )}: ${
    rawData.preferred_contact_channel
      ? i18n.t(`contact_data_component.${rawData.preferred_contact_channel}`)
      : '-'
  }<br>`;
  return `${phoneText}${additionalPhoneText}${LoginNEWMailText}${mailText}${preferredContactText}`;
}

export function applicantObject(personData) {
  return (
    personalDataObject(personData) +
    addressDataObject(personData.address) +
    contactDataObject(personData)
  );
}

export function plantOperatorObject(rawData) {
  return rawData.applicant_role !== PLANT_OPERATOR
    ? personalDataObject(rawData.plant_operator) +
        addressDataObject(rawData.plant_operator.address) +
        contactDataObject(rawData.plant_operator)
    : i18n.t('feed_in_system_details.applicant');
}

export function invoiceAddressObject(rawData) {
  return (
    personalDataObject(rawData.invoice_address) +
    addressDataObject(rawData.invoice_address.address)
  );
}

export function transformOrderQuestions(rawData) {
  let presentableQuestions = '';
  rawData.forEach((question) => {
    presentableQuestions += `<div class="mb-2"></div><strong>${question.title}</strong><br>${question.note}<br></div>`;
  });
  return presentableQuestions;
}

export function installationAddressObject(rawData) {
  const installationAddress = rawData.product_installation_full_address
    ? installationFullAddressObject(rawData.product_installation_full_address)
    : installationPlotAddressObject(
        rawData.product_installation_with_plot_number_address
      );
  return installationAddress;
}

export function installationFullAddressObject(fullAddress) {
  return `${fullAddress.street} ${fullAddress.street_number}, ${fullAddress.zip} ${fullAddress.city}`;
}

function installationPlotAddressObject(plotAddress) {
  return `${i18n.t('order_details.new_building_area_name')} ${
    plotAddress.new_building_area_name || '-'
  } <br>
  ${i18n.t('clerk_orders_overview_table.district')} ${plotAddress.district} <br>
  ${i18n.t('clerk_orders_overview_table.landing')} ${plotAddress.landing} <br>
  ${i18n.t('clerk_orders_overview_table.plot')} ${plotAddress.plot} <br>
  ${plotAddress.zip}, ${plotAddress.location}`;
}

export function additionalContactPersonObject(rawData) {
  return rawData.additional_contact_person &&
    rawData.additional_contact_person.name
    ? personalDataObject(rawData.additional_contact_person) +
        contactDataObject(rawData.additional_contact_person)
    : '-';
}

export function landownerObject(rawData) {
  return rawData.same_landowner === false
    ? personalDataObject(rawData.landowner) +
        addressDataObject(rawData.landowner.address) +
        (rawData.landowner.additional_contact_person
          ? `<br> ${i18n.t('order_details.additional_contact_person')}: <br>` +
            additionalContactPersonObject(rawData.landowner)
          : '')
    : i18n.t('feed_in_system_details.applicant');
}

export function wallboxLocationObject(rawData) {
  if (rawData.wallbox_location === 'wallbox_location_custom') {
    return `${i18n.t('wallbox_location.individual_location')}: ${
      rawData.wallbox_location_custom_value
    }`;
  } else {
    return i18n.t(`wallbox_location.${rawData.wallbox_location}`);
  }
}

export function MeasureConceptObject(rawData) {
  return `${i18n.t('feed_in_system_details.measurement_concept')}: ${
    rawData.measure_concept
  }`;
}

export function feedInCompensationObject(rawData) {
  const feedInCompensation = `${i18n.t(
    'feed_in_system_details.feed_in_compensation'
  )}: ${i18n.t(`feed_in_compensation.${rawData.feed_in_compensation}`)}`;
  return `${feedInCompensation} <br> ${tenantElectricitySurchargeObject(
    rawData.tenant_electricity_surcharge
  )}`;
}

export function tenantElectricitySurchargeObject(value) {
  return `${i18n.t('feed_in_system_details.tenant_electricity_surcharge')}: ${
    value ? 'Mieterstromzuschlag' : 'Kein Mieterstromzuschlag'
  }`;
}

export function showSystemProtectionInHistoryTable(systemProtection) {
  let result = '';

  if (
    systemProtection.built_in_system_protection !== 0 &&
    systemProtection.built_in_system_protection !== null
  ) {
    result += `Integrierter NA-Schutz: ${systemProtection.built_in_system_protection} V <br>`;
  }

  if (
    systemProtection.central_system_protection !== 0 &&
    systemProtection.central_system_protection !== null
  ) {
    result += `Zentraler NA-Schutz: ${systemProtection.central_system_protection} V <br>`;
  }

  if (systemProtection.trip_circuit_completed === true) {
    result += `Auslösetest „zentraler NA-Schutz - Kuppelschalter“ erfolgreich durchgeführt: Ja <br>`;
  }

  if (systemProtection.trip_test_completed === true) {
    result += `Auslösetest „zentraler NA-Schutz - Kuppelschalter“ erfolgreich durchgeführt: Ja <br>`;
  }

  return result;
}

export function maStRObject(rawData) {
  if (rawData) {
    const eegNumber = `${i18n.t(
      'feed_in_system_details.register_number_EEG'
    )}: ${rawData.EEG_number ? rawData.EEG_number : '-'} <br>`;
    const seeNumber = `${i18n.t(
      'feed_in_system_details.register_number_SEE'
    )}: ${rawData.storage_number ? rawData.storage_number : '-'} <br>`;
    const eegDate = `${i18n.t(
      'feed_in_system_details.EEG_commissioning_date'
    )}: ${
      rawData.EEG_implementing_date
        ? dayjs(rawData.EEG_implementing_date).locale(de).format('DD MMMM YYYY')
        : '-'
    } <br>`;
    const registrationDate = `${i18n.t(
      'feed_in_system_details.registration_date'
    )}: ${
      rawData.register_date
        ? dayjs(rawData.register_date).locale(de).format('DD MMMM YYYY')
        : '-'
    } <br>`;
    return `${eegNumber} ${seeNumber} ${eegDate} ${registrationDate}`;
  } else {
    i18n.t('market_data_register.no_data');
  }
}

export function pvSystemChangedObject(rawData) {
  const systemType = `${i18n.t('photovoltaic_system_step.type')}: ${
    rawData.pv_system_infos.pv_system_type === PV_SYSTEM_TYPES.pv_system
      ? i18n.t('photovoltaic_system_step.photovoltaic_system')
      : i18n.t('photovoltaic_system_step.plug_in_system')
  } <br>`;

  const systemPower = `${i18n.t(
    'photovoltaic_system_step.plant_performance'
  )}: ${rawData.pv_system_infos.pv_system_power_W / 1000} ${i18n.t(
    'photovoltaic_system_step.kWp'
  )}<br>`;

  const modulesNumber = `${i18n.t(
    'photovoltaic_system_step.modules_number_text'
  )}: ${
    rawData.pv_system_infos.modules_number > 0
      ? rawData.pv_system_infos.modules_number
      : '-'
  } <br>`;

  const singleModulePerformance = `${i18n.t(
    'photovoltaic_system_step.single_module_performance_text'
  )}: ${
    rawData.pv_system_infos.single_module_performance_W > 0
      ? `${rawData.pv_system_infos.single_module_performance_W / 1000} ${i18n.t(
          'photovoltaic_system_step.kW'
        )}`
      : '-'
  } <br>`;

  const invertersNumber = `${i18n.t(
    'photovoltaic_system_step.inverter_number'
  )}: ${
    rawData.pv_system_infos.inverters_number > 0
      ? rawData.pv_system_infos.inverters_number
      : '-'
  } <br>`;

  const singleInverterPerformance = `${i18n.t(
    'photovoltaic_system_step.single_inverter_performance'
  )}: ${
    rawData.pv_system_infos.single_inverter_performance_W > 0
      ? `${
          rawData.pv_system_infos.single_inverter_performance_W / 1000
        } ${i18n.t('photovoltaic_system_step.kW')}`
      : '-'
  } <br>`;

  const inverterPerformance = `${i18n.t(
    'photovoltaic_system_step.inverter_power'
  )}: ${
    rawData.pv_system_infos.inverter_performance_W > 0
      ? `${rawData.pv_system_infos.inverter_performance_W / 1000} ${i18n.t(
          'photovoltaic_system_step.kW'
        )}`
      : '-'
  } <br>`;
  const maxEffectivePower = `${i18n.t(
    'photovoltaic_system_step.max_effective_power'
  )}: ${
    rawData.pv_system_infos.max_effective_power_W > 0
      ? `${rawData.pv_system_infos.max_effective_power_W / 1000} ${i18n.t(
          'photovoltaic_system_step.kW'
        )}`
      : '-'
  } <br>`;
  const maxApparentPower = `${i18n.t(
    'photovoltaic_system_step.max_apparent_power'
  )}: ${
    rawData.pv_system_infos.max_apparent_power_VA > 0
      ? `${rawData.pv_system_infos.max_apparent_power_VA / 1000} ${i18n.t(
          'photovoltaic_system_step.kVA'
        )}`
      : '-'
  } <br>`;

  return `${systemType} ${modulesNumber} ${singleModulePerformance} ${systemPower} ${invertersNumber} ${singleInverterPerformance} ${inverterPerformance} ${maxEffectivePower} ${maxApparentPower}`;
}

export function pvInverterObject(inverters) {
  return inverters
    .map((el) => {
      const inverterId = `${i18n.t('inverter_model_step.id')}: ${
        el.id ? el.id : '-'
      } <br>`;
      const manufacturer = `${i18n.t('inverter_model_step.manufacturer')}: ${
        el.manufacturer
      } <br>`;
      const type = `${i18n.t('inverter_model_step.type')}: ${el.type} <br>`;
      const numberOfInverters = `${i18n.t('inverter_model_step.amount')}: ${
        el.number_of_inverters
      } <br>`;
      const activePower = `${i18n.t(
        'inverter_model_step.active_power_text'
      )}: ${numberToGermanFormat(el.active_power)} ${i18n.t(
        'inverter_model_step.W'
      )}<br>`;
      const apparentPower = `${i18n.t(
        'inverter_model_step.apparent_power_text'
      )}: ${numberToGermanFormat(el.apparent_power)} ${i18n.t(
        'inverter_model_step.V'
      )}<br>`;
      const ratedCurrent = `${i18n.t(
        'inverter_model_step.rated_current'
      )}: ${numberToGermanFormat(el.rated_current)} ${i18n.t(
        'inverter_model_step.A'
      )}<br>`;
      const e4CertificateNumber = `${i18n.t(
        'inverter_model_step.e4_cert_number'
      )}: ${numberToGermanFormat(el.e4_cert_number)} <br>`;
      const e6CertificateNumber = `${i18n.t(
        'inverter_model_step.e6_cert_number'
      )}: ${numberToGermanFormat(el.e6_cert_number)} <br>`;
      const e4CertificateFileName = `${i18n.t(
        'inverter_model_step.e4_cert_file'
      )}: ${
        el.e4_certificate_file_name ? el.e4_certificate_file_name : '-'
      } <br>`;
      const e6CertificateFileName = `${i18n.t(
        'inverter_model_step.e6_cert_file'
      )}: ${
        el.e6_certificate_file_name ? el.e6_certificate_file_name : '-'
      } <br>`;

      return `${manufacturer} ${type} ${numberOfInverters} ${inverterId} ${activePower} ${apparentPower} ${ratedCurrent} ${e4CertificateNumber} ${e6CertificateNumber} ${e4CertificateFileName} ${e6CertificateFileName}`;
    })
    .join('<br>');
}

export function emobilityInstallationObject(rawData) {
  switch (rawData.e_mobility_installation) {
    case 'charging_station_custom':
      return `${i18n.t('emobility_details.charging_station_custom')} ${
        rawData.charging_station_custom_value
      } kW`;
    default:
      return i18n.t(`emobility_details.${rawData.e_mobility_installation}`);
  }
}

export function changedByObject(item) {
  return item.last_changed_by
    ? `${item.last_changed_by}, ${formatDate(item.last_update, 'DD.MM.YYYY')}`
    : '';
}

export function formatDate(date, format) {
  return dayjs(new Date(date)).format(format);
}

export function mapProductName(products) {
  return i18n.t(`products.${products}.title`);
}

export function dateObject(rawData) {
  let presentableDate = '';
  if (
    !rawData.appointment_for_connection ||
    rawData.appointment_for_connection === NOT_ARRANGED_YET
  ) {
    presentableDate += i18n.t(`order_details.not_arranged_yet`);
  } else if (rawData.date_unit === 'day') {
    presentableDate += presentableDayDate(rawData.appointment_for_connection);
  } else {
    presentableDate += presentableWeekDate(rawData.appointment_for_connection);
  }
  return presentableDate;
}

export function presentableWeekDate(weekDate) {
  const startOfWeek = dayjs(weekDate).startOf('isoWeek').format('DD');
  const endOfWeek = dayjs(weekDate)
    .locale('de')
    .startOf('isoWeek')
    .add(4, 'days');
  const calendarWeek = dayjs(weekDate).week().toString();
  return `${startOfWeek}. - ${endOfWeek.format('D')}. ${endOfWeek.format(
    'MMMM YYYY'
  )}, Kalenderwoche ${calendarWeek}`;
}

export function presentableDayDate(dayDate) {
  const calendarWeek = dayjs(dayDate).week().toString();
  return `${dayjs(dayDate)
    .locale('de')
    .format('DD. MMMM YYYY')}, Kalenderwoche ${calendarWeek}`;
}

export function dateWithUnitObject(rawData) {
  let presentableDate = '';
  presentableDate += rawData.date_unit
    ? presentableUnitDate(rawData.date_unit)
    : '';
  presentableDate += dateObject(rawData);
  return presentableDate;
}

function presentableUnitDate(dateUnit) {
  const unitTranslation = i18n.t('history.date_unit');
  const unitValue = i18n.t(`calendar_component.${dateUnit}`);
  return `${unitTranslation}: ${unitValue}<br>`;
}

export function installationCompanyObject(rawData) {
  let installationCompanies = '';
  if (rawData.installation_company_gas) {
    installationCompanies +=
      `${i18n.t('products.gas&water.title')}: ` +
      presentableInstallerCompany(rawData.installation_company_gas);
  }
  if (rawData.installation_company_electricity) {
    if (rawData.installation_company_gas) {
      installationCompanies += '<br>';
    }
    installationCompanies +=
      `${i18n.t('products.electricity.title')}: ` +
      presentableInstallerCompany(rawData.installation_company_electricity);
  }
  return installationCompanies;
}

function presentableInstallerCompany(companyData) {
  switch (companyData) {
    case 'not_selected_yet':
      return i18n.t('installer_component.not_selected_yet');
    case 'not_listed':
      return i18n.t('installer_component.not_listed');
    default:
      return companyData;
  }
}

export function financeDataObject(rawData) {
  return (
    translateOperatorData(rawData) +
    presentableAccountData(rawData.account_data) +
    presentableTaxData(rawData.tax_data)
  );
}

function translateOperatorData(rawData) {
  return [
    {
      exists: true,
      value: rawData.is_small_company
        ? i18n.t('operator_type.small_company')
        : i18n.t('operator_type.no_small_company'),
    },
    {
      exists: rawData.is_tax_exempt,
      value: i18n.t('operator_type.turnover_tax_exempt'),
    },
    {
      exists: rawData.is_tax_exempt,
      value: `${i18n.t('operator_type.reason')}: ${rawData.tax_exempt_reason}`,
    },
  ]
    .filter((part) => part.exists)
    .reduce((acc, part) => acc.concat(`${part.value} <br>`), '');
}

function presentableAccountData({ account_owner, iban, bank, bic }) {
  const formattedIBAN = insertSpacesToIBAN(iban);

  return `
    ${account_owner}
    ${newLine}
    ${formattedIBAN}
    ${newLine}
    ${bank}
    ${newLine}
    BIC: ${bic}
    ${newLine}
  `;
}

export function insertSpacesToIBAN(IBAN) {
  // Remove any existing spaces from the IBAN string
  const cleanedIBAN = IBAN.replace(/\s/g, '');

  // insert a space every 4 characters
  const formattedIBAN = cleanedIBAN.replace(/(.{4})/g, '$1 ');

  // Trim any leading/trailing spaces and return the formatted string
  return formattedIBAN.trim();
}

function presentableTaxData(rawData) {
  let taxData = '';
  if (rawData.tax_number) {
    taxData += `${i18n.t('feed_in_system_details.tax_id')}: ${
      rawData.tax_number
    }<br>`;
  }
  if (rawData.vat_number) {
    taxData += `${i18n.t('feed_in_system_details.vat_number')}: ${
      rawData.vat_number
    }`;
  }
  return taxData;
}

export function presentableFeedInCompensation(rawData) {
  return (
    translateFeedInCompensation(rawData.feed_in_compensation) +
    translatedTenantElectricitySurcharge(rawData.tenant_electricity_surcharge)
  );
}

function translateFeedInCompensation(feedInCompensation) {
  return i18n.t(`feed_in_compensation.${feedInCompensation}`) + '<br>';
}

function translatedTenantElectricitySurcharge(tenant_electricity_surcharge) {
  return tenant_electricity_surcharge
    ? i18n.t('feed_in_compensation.tenant_power_surcharge')
    : i18n.t('feed_in_compensation.no_tenant_power_surcharge');
}

export function maStRDataObject(maStR) {
  if (
    !maStR.EEG_number &&
    !maStR.storage_number &&
    !maStR.EEG_implementing_date &&
    !maStR.register_date
  )
    return i18n.t('market_data_register.no_data');
  return `${i18n.t('market_data_register.register_number_EEG')}: ${
    maStR.EEG_number || '-'
  }<br> ${i18n.t('market_data_register.register_number_SEE')}: ${
    maStR.storage_number || '-'
  }<br>
  ${i18n.t('market_data_register.EEG_commissioning_date')}: ${
    maStR.EEG_implementing_date
      ? dayjs(maStR.EEG_implementing_date).locale('de').format('DD.MM.YYYY')
      : '-'
  }<br>
  ${i18n.t('market_data_register.registration_date')}: ${
    maStR.register_date
      ? dayjs(maStR.register_date).locale('de').format('DD.MM.YYYY')
      : '-'
  }`;
}

export function storageMaStRDataObject(storageMaStR) {
  if (
    !storageMaStR.EEG_number &&
    !storageMaStR.storage_number &&
    !storageMaStR.EEG_implementing_date &&
    !storageMaStR.register_date
  )
    return i18n.t('storage_market_data_register.no_data');
  return `${i18n.t('storage_market_data_register.register_number_EEG')}: ${
    storageMaStR.EEG_number || '-'
  }<br> ${i18n.t('storage_market_data_register.register_number_SEE')}: ${
    storageMaStR.storage_number || '-'
  }<br>
  ${i18n.t('storage_market_data_register.EEG_commissioning_date')}: ${
    storageMaStR.EEG_implementing_date
      ? dayjs(storageMaStR.EEG_implementing_date)
          .locale('de')
          .format('DD.MM.YYYY')
      : '-'
  }<br>
  ${i18n.t('storage_market_data_register.registration_date')}: ${
    storageMaStR.register_date
      ? dayjs(storageMaStR.register_date).locale('de').format('DD.MM.YYYY')
      : '-'
  }`;
}

export function pvSystemInfoObject(pvSystemInfo) {
  const system_type = `${i18n.t(
    `feed_in_system_details.${pvSystemInfo.pv_system_type}`
  )} <br>`;
  const system_power = `${pvSystemInfo.pv_system_power_W / 1000} ${i18n.t(
    'feed_in_system_details.kWp'
  )}<br>`;

  const inverter_power =
    pvSystemInfo.inverter_performance_W > 0
      ? `${pvSystemInfo.inverter_performance_W / 1000} ${i18n.t(
          'feed_in_system_details.kW'
        )} <br>`
      : '- <br>';

  const maxEffectivePower =
    pvSystemInfo.max_effective_power_W > 0
      ? `${pvSystemInfo.max_effective_power_W / 1000} ${i18n.t(
          'feed_in_system_details.kW'
        )}<br>`
      : '- <br>';

  const maxApparentPower =
    pvSystemInfo.max_apparent_power_VA > 0
      ? `${pvSystemInfo.max_apparent_power_VA / 1000} ${i18n.t(
          'feed_in_system_details.kVA'
        )}<br>`
      : '- <br>';

  return `
    ${system_type} ${system_power} ${inverter_power} ${
    pvSystemInfo.max_effective_power_W ? maxEffectivePower : ''
  } ${pvSystemInfo.max_apparent_power_VA ? maxApparentPower : ''}`;
}

export function energyStorageObject({ energy_storage, is_storage_planned }) {
  if (energy_storage === null || !energy_storage) {
    return is_storage_planned
      ? i18n.t('storage.storage_planned')
      : i18n.t('storage.storage_not_planned');
  }

  return [
    {
      exists: energy_storage?.manufacturer,
      title: i18n.t('storage.manufacturer'),
      value: energy_storage.manufacturer,
    },
    {
      exists: energy_storage?.type,
      title: i18n.t('storage.type'),
      value: energy_storage.type,
    },
    {
      exists: energy_storage?.number_of_inverters,
      title: i18n.t('storage.amount'),
      value: energy_storage.number_of_inverters,
    },
    {
      exists: energy_storage?.coupled,
      title: i18n.t('storage.connection_type'),
      value: `${i18n.t(
        `storage.${energy_storage.coupled?.toLowerCase()}_coupled`
      )}, ${i18n.t(`storage.${energy_storage.current}`)}`,
    },
    {
      exists: energy_storage?.isolated,
      title: i18n.t('storage.microgrid_building_system'),
      value: energy_storage.isolated
        ? i18n.t('storage.yes')
        : i18n.t('storage.no'),
    },
    {
      exists: energy_storage?.useful_capacity_wh,
      title: i18n.t('storage.usable_storage_capacity'),
      value: `${energy_storage.useful_capacity_wh / 1000} ${i18n.t(
        'storage.kWh'
      )}`,
    },
    {
      exists: energy_storage?.number_of_phases,
      title: i18n.t('storage.grid_feeding'),
      value: i18n.t(`storage.${energy_storage.number_of_phases}-phase`),
    },
    {
      exists: energy_storage.storage_inverter?.active_power,
      title: i18n.t('storage.effective_power_inverter'),
      value: `${energy_storage.storage_inverter?.active_power / 1000} ${i18n.t(
        'storage.kW'
      )}`,
    },
    {
      exists: energy_storage.storage_inverter?.apparent_power,
      title: i18n.t('storage.apparent_power_inverter_plain'),
      value: `${
        energy_storage.storage_inverter?.apparent_power / 1000
      } ${i18n.t('storage.kVA')}`,
    },
    {
      exists: energy_storage.storage_inverter?.rated_current,
      title: i18n.t('storage.rated_current'),
      value: `${energy_storage.storage_inverter?.rated_current} ${i18n.t(
        'storage.A'
      )}`,
    },
    {
      exists: energy_storage.storage_inverter?.shift_factor,
      title: i18n.t('storage.shift_factor'),
      value: `${energy_storage.storage_inverter?.shift_factor} ${i18n.t(
        'storage.cos'
      )}`,
    },
    {
      exists: energy_storage?.storage_loading_without_procurement,
      title: '',
      value: i18n.t('storage.storage_loading_without_procurement'),
    },
    {
      exists: energy_storage?.storage_loading_with_procurement,
      title: '',
      value: i18n.t('storage.storage_loading_with_procurement'),
    },
    {
      exists: energy_storage?.storage_discharge_without_procurement,
      title: '',
      value: i18n.t('storage.storage_discharge_without_procurement'),
    },
    {
      exists: energy_storage?.storage_discharge_with_procurement,
      title: '',
      value: i18n.t('storage.storage_discharge_with_procurement'),
    },
    {
      exists: energy_storage?.trickle_charge_deactivated,
      title: '',
      value: i18n.t('storage.trickle_charge_deactivated'),
    },
    {
      exists: energy_storage?.function_test_completed,
      title: '',
      value: i18n.t('storage.function_test_completed'),
    },
    {
      exists: energy_storage?.has_power_flow_monitoring_certificate,
      title: '',
      value: i18n.t('storage.has_power_flow_monitoring_certificate'),
    },
  ]
    .filter((part) => part.exists)
    .reduce(
      (acc, part) =>
        acc.concat(
          `<div class="list-item">${part.title}${part.title ? ':' : ''} ${
            part.value
          } <br></div>`
        ),
      ''
    );
}

export function measurementConceptObject(rawData) {
  return `${rawData.measure_concept} <br>`;
}

export function feedInProspectObject(rawData) {
  const prospect = rawData.feed_in_prospect_wh / 1000;
  if (prospect) {
    return `${numberToGermanFormat(prospect)} ${i18n.t(
      'feed_in_system_details.kWh'
    )}<br>`;
  }
}

function alternateInvoiceRecipientOrAlternateDeliveryAddressData(recipient) {
  if (!recipient) {
    return `${i18n.t('feed_in_system_details.not_specified')}`;
  }

  let dataText = '';

  if (recipient.title) {
    dataText += `${recipient.title} `;

    if (recipient.title === 'Firma') {
      dataText += `${recipient.company_name} `;
    } else if (recipient.title === 'Stadt/Kommune') {
      dataText += `${recipient.city_name} `;
    } else {
      dataText += `${recipient.name} ${recipient.surname}`;
    }
  }

  if (dataText) {
    dataText += ', <br>';
  }

  if (recipient.address) {
    if (recipient.address.street) {
      dataText += `${recipient.address.street} `;
    }
    if (recipient.address.street_number) {
      dataText += `${recipient.address.street_number}, <br>`;
    }
    if (recipient.address.zip) {
      dataText += `${recipient.address.zip} `;
    }
    if (recipient.address.city) {
      dataText += `${recipient.address.city}`;
    }
  }

  return dataText;
}

export function alternateInvoiceRecipientDataObject(rawData) {
  return alternateInvoiceRecipientOrAlternateDeliveryAddressData(
    rawData.alternate_invoice_recipient
  );
}

export function alternateDeliveryAddressDataObject(rawData) {
  return alternateInvoiceRecipientOrAlternateDeliveryAddressData(
    rawData.alternate_delivery_address
  );
}

export function pvContactDataObject(rawData) {
  let contactDataText = '';
  if (rawData.applicant_role) {
    contactDataText += `${i18n.t(
      'contract_contact.applicant_is_also'
    )} ${i18n.t(`contract_contact.${rawData.applicant_role}`)}:`;
  }
  if (rawData.applicant) {
    contactDataText += `<br>${applicantObject(rawData.applicant)}<br>`;
  }
  if (rawData.plant_operator) {
    contactDataText += `
    ${i18n.t(
      'feed_in_system_details.plant_operator'
    )}:<br>${plantOperatorObject(rawData)}<br>`;
  }
  if (rawData.landowner) {
    contactDataText += `
    ${i18n.t('order_details.landowner')}:<br>${landownerObject(rawData)}<br>`;
  }
  return contactDataText;
}

export function appointmentObject(rawData) {
  return presentableDayDate(rawData.implementing_date);
}

function counterDataObject(rawData) {
  const counterIdArray = rawData?.counter_id_data;
  if (counterIdArray) {
    let counterIds = '';
    counterIdArray.forEach((counterData) => {
      counterIds += `${counterData.counter_id || counterData.file_name}${
        counterData.two_way_measurement_built_in
          ? `, ${i18n.t('counter_availability_step.two_way_counter')}`
          : ''
      }<br>`;
    });
    return counterIds;
  } else {
    const newCounterData = rawData?.new_counter_data;
    return `
    ${i18n.t('counter_availability_step.counter_not_available.subtitle')}<br> 
    ${
      newCounterData.commissioning_application_file_name?.replace(
        'new_counter_1_',
        '',
        1
      ) || '-'
    } <br>
    ${i18n.t(
      'counter_availability_step.counter_not_available.simultaneous_power'
    )}: ${newCounterData.simultaneous_power_w / 1000}kW <br>${i18n.t(
      'counter_availability_step.counter_not_available.consumption_prospect'
    )}: ${newCounterData.consumption_prospect_wh / 1000}kWh`;
  }
}

export function counterDataHistoryObject(rawData) {
  let result = '';

  // Handle the case where counter_id_data exists
  const counterIdArray = rawData.counter_id_data;
  if (counterIdArray) {
    counterIdArray.forEach((counterData, index) => {
      result += `<br>${i18n.t('feed_in_system_details.counter_number')} ${
        index + 1
      }: ${counterData.counter_id}<br>${i18n.t(
        'feed_in_system_details.file'
      )} ${index + 1}: ${
        counterData.file_name ? counterData.file_name : '-'
      }<br>${i18n.t('counter_availability_step.is_for_counter')} ${
        index + 1
      } ${i18n.t(
        'counter_availability_step.a_two_way_measurement_installed'
      )}: ${
        counterData.two_way_measurement_built_in
          ? i18n.t('feed_in_system_details.yes')
          : i18n.t('feed_in_system_details.no')
      }<br>`;
    });
    result += `<br>${i18n.t('counter_availability_step.counter_to_remove')}: ${
      rawData.counter_id_to_remove ? rawData.counter_id_to_remove : '-'
    }`;
  }

  // Handle the case where new_counter_data exists
  const newCounterData = rawData.new_counter_data;
  if (newCounterData) {
    result += `<br>${i18n.t(
      'counter_availability_step.counter_not_available.subtitle'
    )}<br>${
      newCounterData.commissioning_application_file_name
        ? newCounterData.commissioning_application_file_name.replace(
            'new_counter_1_',
            ''
          )
        : '-'
    }<br>${i18n.t(
      'counter_availability_step.counter_not_available.simultaneous_power'
    )}: ${(newCounterData.simultaneous_power_w / 1000).toFixed(
      1
    )} kW<br>${i18n.t(
      'counter_availability_step.counter_not_available.consumption_prospect'
    )}: ${(newCounterData.consumption_prospect_wh / 1000).toFixed(1)} kWh`;
  }

  return result;
}

export function presentableCounterData(rawData) {
  return counterDataObject(rawData);
}
export function existingSystemsObject(existingSystems) {
  return [
    {
      name: i18n.t('feed_in_system_details.photovoltaic_system'),
      power: existingSystems.pv_system_W / 1000,
      unit: i18n.t('feed_in_system_details.kWp'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(existingSystems.pv_system_implementing_date),
    },
    {
      name: i18n.t('feed_in_system_details.plug_in_photovoltaic_system'),
      power: existingSystems.plugin_pv_system_W,
      unit: i18n.t('feed_in_system_details.W'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems.plugin_pv_system_implementing_date
      ),
    },
    {
      name: i18n.t('feed_in_system_details.cogeneration_unit'),
      power: existingSystems.cogeneration_VA / 1000,
      unit: i18n.t('feed_in_system_details.kVA'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems.cogeneration_implementing_date
      ),
    },
    {
      name: i18n.t('feed_in_system_details.wind_power'),
      power: existingSystems.wind_power_VA / 1000,
      unit: i18n.t('feed_in_system_details.kVA'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems.wind_power_implementing_date
      ),
    },
    {
      name: i18n.t('feed_in_system_details.standby_system'),
      power: existingSystems.standby_system_VA / 1000,
      unit: i18n.t('feed_in_system_details.kVA'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems.standby_system_implementing_date
      ),
    },
    {
      name: existingSystems.other_system_name,
      power: existingSystems.other_system_VA / 1000,
      unit: i18n.t('feed_in_system_details.kVA'),
      date_label: i18n.t('feed_in_system_details.commissioning_date'),
      date: formatDateStringOrDash(
        existingSystems.other_system_implementing_date
      ),
    },
  ].filter((system) => !!system.power);
}

export function measurementLocationObject(rawData) {
  return `<br>${
    rawData?.pvimplementation_inspection?.counting_method
      ? rawData.pvimplementation_inspection.counting_method
      : '-'
  }<br>${
    rawData?.pvimplementation_inspection?.measuring_location_provider
      ? rawData.pvimplementation_inspection.measuring_location_provider
      : '-'
  }`;
}

/**
 * Actually only the date is transformed.
 * @param {*} counterData values for a given Counter
 * @param {*} key the key used for translation
 * @returns formatted counter data
 */
export function formatCounterData(counterData, key) {
  if (key === 'valid_from') {
    try {
      counterData = formatDate(counterData, 'DD.MM.YYYY');
    } catch (err) {
      console.error(err);
    }
  } else if (key === 'current_direction') {
    counterData = getTranslatedValue(counterData, CURRENT_DIRECTION_LIST);
  } else if (key === 'type_of_usage') {
    counterData = getTranslatedValue(counterData, type_of_usage_options);
  } else if (key === 'pre_decimal_places' || key === 'decimal_places') {
    if (counterData === 0) {
      return 0;
    }
  }
  return counterData ? counterData : '-';
}

function getTranslatedValue(inputValue, listValues) {
  return listValues.find((option) => option.value === inputValue)?.translation;
}

export function marketLocationObject(rawData) {
  return `<br>${
    rawData?.pvimplementationprotocol_inspection?.market_location
      ? rawData.pvimplementationprotocol_inspection.market_location
      : '-'
  }<br>${
    rawData?.pvimplementation_inspection?.market_location_provider
      ? rawData.pvimplementation_inspection.market_location_provider
      : '-'
  }`;
}
function formatDateStringOrDash(date) {
  return date ? formatDate(date, 'DD.MM.YYYY') : '-';
}

export function powerFlowMonitoringObject(rawData) {
  const powerFlowObject = rawData.power_flow_monitoring;
  const isPowerFlowMonitored = `${i18n.t(
    'power_flow_monitoring.is_power_flow_monitored'
  )}: ${
    powerFlowObject?.is_power_flow_monitored
      ? i18n.t('power_flow_monitoring.yes')
      : i18n.t('power_flow_monitoring.no')
  }`;
  const monitoredPowerFlowInW = `${i18n.t(
    'power_flow_monitoring.monitored_power_flow_in_w'
  )}: ${powerFlowObject?.monitored_power_flow_W / 1000} ${i18n.t(
    'power_flow_monitoring.kW'
  )}`;

  return `${isPowerFlowMonitored} <br> ${
    powerFlowObject?.is_power_flow_monitored ? monitoredPowerFlowInW : ''
  }`;
}

export function systemProtectionObject(rawData) {
  const systemProtectionObject = rawData?.system_protection;

  const builtInSystemProtection = `${i18n.t(
    'system_protection.built_in_protection'
  )}: ${systemProtectionObject?.built_in_system_protection} V`;

  const centralSystemProtection = `${i18n.t(
    'system_protection.central_protection'
  )}: ${systemProtectionObject?.central_system_protection} V <br>`;

  const tripTestCompleted = `${i18n.t('system_protection.trip_test_label')}: ${
    systemProtectionObject?.trip_test_completed
      ? i18n.t('system_protection.yes')
      : i18n.t('system_protection.no')
  } <br>`;

  const tripCircuitCompleted = `${i18n.t(
    'system_protection.trip_test_label'
  )}: ${
    systemProtectionObject?.trip_circuit_completed
      ? i18n.t('system_protection.yes')
      : i18n.t('system_protection.no')
  } <br>`;

  return systemProtectionObject?.built_in_system_protection
    ? builtInSystemProtection
    : `${centralSystemProtection} ${tripTestCompleted} ${tripCircuitCompleted}`;
}

export function symmetryRequirementObject(rawData) {
  const symetryObject = rawData?.symmetry_requirement;

  const type = i18n.t(`symmetry_requirement.${symetryObject?.type}`);

  const isSinglePhase =
    symetryObject?.type === 'single_phase_connected_inverter';

  const outerConductorL1 = isSinglePhase
    ? `<br> ${i18n.t('symmetry_requirement.outer_conductor_l1')}: ${
        symetryObject?.outer_conductor_l1_VA / 1000
      } ${i18n.t('symmetry_requirement.kVA')}`
    : '';

  const outerConductorL2 = isSinglePhase
    ? `<br> ${i18n.t('symmetry_requirement.outer_conductor_l2')}: ${
        symetryObject?.outer_conductor_l2_VA / 1000
      } ${i18n.t('symmetry_requirement.kVA')}`
    : '';

  const outerConductorL3 = isSinglePhase
    ? `<br> ${i18n.t('symmetry_requirement.outer_conductor_l3')}: ${
        symetryObject?.outer_conductor_l3_VA / 1000
      } ${i18n.t('symmetry_requirement.kVA')}`
    : '';

  return `${type} ${outerConductorL1} ${outerConductorL2} ${outerConductorL3}`;
}

export function reactivePowerProvisionObject(rawData) {
  const reactivePowerProvision = rawData?.reactive_power_provision;
  return i18n.t(`reactive_power_provision.${reactivePowerProvision}`);
}

export function pvFeedinManagementObject(feedin_management) {
  if (!feedin_management) {
    return '-';
  }
  return i18n.t(`feedin_management.${feedin_management.toString()}`);
}
