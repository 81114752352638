export const feedInSystemsStorageMarketDataRegisterModule = {
  state: {},
  mutations: {
    setStorageMarketRegisterNumberEEG(state, EEG_number) {
      this.state.changed_object.storage_maStR.EEG_number = EEG_number;
    },
    setStorageMarketRegisterNumberSEE(state, storage_number) {
      this.state.changed_object.storage_maStR.storage_number = storage_number;
    },
    setStorageEEGCommissioningDate(state, EEG_implementing_date) {
      this.state.changed_object.storage_maStR.EEG_implementing_date =
        EEG_implementing_date;
    },
    setStorageMarketRegistrationDate(state, register_date) {
      this.state.changed_object.storage_maStR.register_date = register_date;
    },
  },
  getters: {
    getStorageMarketRegisterNumberEEG: (state, getters, rootState) => {
      return rootState.changed_object.storage_maStR?.EEG_number || '';
    },
    getStorageMarketRegisterNumberSEE: (state, getters, rootState) => {
      return rootState.changed_object.storage_maStR?.storage_number || '';
    },
    getStorageEEGCommissioningDate: (state, getters, rootState) => {
      return (
        rootState.changed_object.storage_maStR?.EEG_implementing_date || ''
      );
    },
    getStorageMarketRegistrationDate: (state, getters, rootState) => {
      return rootState.changed_object.storage_maStR?.register_date || '';
    },
  },
};
