var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('RowItem',{staticClass:"row-item",attrs:{"first":"","title":_vm.$t('feed_in_system_details.request_date'),"editable":false}},[_vm._v(" "+_vm._s(_vm.presentableFeedInSystem.requestDate)+" ")]),_c('RowItem',{staticClass:"row-item",attrs:{"title":_vm.$t('feed_in_system_details.order_number'),"editable":false}},[_vm._v(" "+_vm._s(_vm.feedInSystem.case_number)+" ")]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVInstallationAddress","title":_vm.$t('feed_in_system_details.feed_in_address'),"editable":true},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'PhotovoltaicInstallationAddressComponent',
        location: 'ContractDetails',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.address)}})]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVContactData","title":_vm.$t('feed_in_system_details.contact_data'),"editable":true},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'ContractContactComponent',
        location: 'ContractDetails',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.contactData)}})]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVAlternateInvoiceRecipient","title":_vm.$t('feed_in_system_details.alternate_invoice_recipient'),"editable":!_vm.disqualifiedStatusesForAlternateRecipientAndAlternateDeliveryAddress.includes(
        this.feedInSystemStatus
      )},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'CompactUserAndAddressFormComponent',
        location: 'ContractDetails',
        properties: { formName: 'invoiceRecipient' },
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.alternateInvoiceRecipient)}})]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVAlternateDeliveryAddress","title":_vm.$t('feed_in_system_details.alternate_delivery_address'),"editable":!_vm.disqualifiedStatusesForAlternateRecipientAndAlternateDeliveryAddress.includes(
        this.feedInSystemStatus
      )},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'CompactUserAndAddressFormComponent',
        location: 'ContractDetails',
        properties: { formName: 'deliveryAddress' },
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.alternateDeliveryAddress)}})]),(!_vm.isPVAnfrage)?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVFeedInCompensation","title":_vm.$t('feed_in_system_details.feed_in_compensation'),"editable":true},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'FeedInCompensationComponent',
        location: 'ContractDetails',
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.feedInCompensation || '-')}})]):_vm._e(),(!_vm.isPVAnfrage)?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updateFinanceData","title":_vm.$t('feed_in_system_details.finance_data'),"editable":true},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'FinanceDataComponent',
        location: 'ContractDetails',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.financeData || '-')}})]):_vm._e(),(!_vm.isPVAnfrage)?_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVMaStRData","title":_vm.$t('market_data_register.title'),"editable":true},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'MarketDataRegisterComponent',
        location: 'ContractDetails',
        properties: _vm.feedInSystem,
      })}}},[_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.maStR || '-')}})])]):_vm._e(),_c('RowItem',{key:_vm.presentableFeedInSystem.plantConstructor,staticClass:"row-item",attrs:{"field":"updatePVElectricInstallerCompany","title":_vm.$t('feed_in_system_details.plant_constructor'),"editable":true},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'ElectricInstallerCompaniesComponent',
        location: 'ContractDetails',
        properties: { allInstallerCompanies: _vm.fetchedInstallerCompanies },
      })}}},[_vm._v(" "+_vm._s(_vm.presentableFeedInSystem.plantConstructor ? _vm.presentableFeedInSystem.plantConstructor : _vm.presentablePlantConstructor)+" ")]),_c('RowItem',{staticClass:"row-item",attrs:{"field":"updatePVCounterId","title":_vm.$t('feed_in_system_details.counter_number'),"editable":_vm.showCounterId},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'CounterAvailabilityComponent',
        location: 'ContractDetails',
        properties: _vm.feedInSystem,
      })}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.presentableFeedInSystem.counterNumber)}})]),(
      _vm.isPVInbetriebnahmeProtokoll &&
      this.feedInSystem.pvimplementationprotocol_inspection
    )?_c('div',_vm._l((_vm.transformedCounters),function(item,index){return _c('RowItem',{key:index,staticClass:"row-item",attrs:{"field":"updatePhotovoltaics","title":_vm.buildLabels(item, index),"editable":true},on:{"edit":function($event){return _vm.$emit('edit-component', {
          component: 'UpdateCounterDataComponent',
          location: 'ContractDetails',
          properties: {
            counter: item,
            indexOfCounter: index,
            allCounters:
              _vm.feedInSystem.pvimplementationprotocol_inspection.counters,
          },
        })}}},[_c('br'),_c('br'),_vm._l((item),function(counterData,key){return _c('div',{key:key},[_vm._v(" "+_vm._s(_vm.formatCounterData(counterData, key))+" ")])})],2)}),1):_vm._e(),_c('RowItem',{staticClass:"row-item",attrs:{"last":"","field":"updatePVImplementationAppointment","title":_vm.$t('feed_in_system_details.implementation_appointment'),"editable":true},on:{"edit":function($event){return _vm.$emit('edit-component', {
        component: 'PVAppointmentDialog',
        location: 'ContractDetails',
      })}}},[_c('div',[_vm._v(" "+_vm._s(_vm.presentableFeedInSystem.implementationAppointment || '-')+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }