import GraphQLService from './GraphQLService';

export default {
  async getInstallerCompanies(params) {
    return GraphQLService.requestWithOpenID(
      `
      query GetCompanies($zip: String, $product: String) { 
        getInstallerCompanies( zip: $zip, product: $product) {
          Strasse
          Hausnummer
          Plz
          Firma
          Ort
          Id
        }
      }`,
      {
        zip: params.zip,
        product: params.product,
      }
    );
  },
};
