export const applicantModule = {
  state: {
    applicant_address_zip_from_google: undefined,
  },
  mutations: {
    setApplicantChangedObject(state, object) {
      this.state.changed_object.applicant[object.key] = object.value;
    },
    setApplicantAddressChangedObject(state, changedData) {
      this.state.changed_object.applicant.address[changedData.key] =
        changedData.value;
    },
    set_form_applicant_address_zip_from_google(state, value) {
      state.applicant_address_zip_from_google = value;
    },
  },
  getters: {
    get_form_applicant_address_zip_from_google: (state) => {
      return state.applicant_address_zip_from_google;
    },
  },
  actions: {},
};
